import {
  CHECKIN_REQUEST,
  CHECKIN_FAILURE,
  CHECKIN_SUCCESS,
  WAITTIME_REQUEST,
  WAITTIME_FAILURE,
  WAITTIME_SUCCESS,
  CHECKIN_CANCEL_REQUEST,
  CHECKIN_CANCEL_FAILURE,
  CHECKIN_CANCEL_SUCCESS,
  CHECKIN_UPDATE_REQUEST,
  CHECKIN_UPDATE_FAILURE,
  CHECKIN_UPDATE_SUCCESS,
  CHECKIN_DETAILS_REQUEST,
  CHECKIN_DETAILS_FAILURE,
  CHECKIN_DETAILS_SUCCESS,
  CLEAR_CHECKIN_STATE,
  CHECKIN_STORE,
  SHOW_RESERVATION_STATUS,
  HIDE_RESERVATION_STATUS,
  FEEDBACK_SUBMITTED,
} from "./checkInConstants";
import {
  CheckInSuccessResult,
  CheckInFailResult,
  CheckInBookingForm,
  WaitTimeRestaurantDetail,
  WaitTime,
  WaitTimeFailResult,
  CancelCheckInDetails,
  CheckInBookedDetail,
} from "./checkInModels";
import { typedAction } from "../actionTypes";

export function clearCheckInState() {
  return typedAction(CLEAR_CHECKIN_STATE, "clear");
}

// CheckIn
export function makeBooking(details: CheckInBookingForm) {
  return typedAction(CHECKIN_REQUEST, details);
}

export function failedBooking(details: CheckInFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(CHECKIN_FAILURE, details);
}

export function successBooking(details: CheckInSuccessResult) {
  return typedAction(CHECKIN_SUCCESS, details);
}

// CheckIn
export function storeBookingDetails(details: CheckInBookingForm) {
  return typedAction(CHECKIN_STORE, details);
}

// WaitTime Fetch
export function getWaitTimes(detail: WaitTimeRestaurantDetail) {
  return typedAction(WAITTIME_REQUEST, detail);
}

export function failedWaitTimes(details: WaitTimeFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(WAITTIME_FAILURE, details);
}

export function successWaitTimes(details: WaitTime[]) {
  return typedAction(WAITTIME_SUCCESS, details);
}

// Cancel CheckIn
export function cancelBooking(details: CancelCheckInDetails) {
  return typedAction(CHECKIN_CANCEL_REQUEST, details);
}

export function failedCancelBooking(details: CheckInFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(CHECKIN_CANCEL_FAILURE, details);
}

export function successCancelBooking() {
  return typedAction(CHECKIN_CANCEL_SUCCESS, {});
}

// Edit Booking
export function editBooking(details: CheckInBookingForm) {
  return typedAction(CHECKIN_UPDATE_REQUEST, details);
}

export function failedEditbooking(details: CheckInFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(CHECKIN_UPDATE_FAILURE, details);
}

export function successEditBooking(details: CheckInSuccessResult) {
  return typedAction(CHECKIN_UPDATE_SUCCESS, details);
}

// Get Booking Details
export function getBooking(reservationID: string) {
  return typedAction(CHECKIN_DETAILS_REQUEST, reservationID);
}

export function failedGetBooking(details: CheckInFailResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(CHECKIN_DETAILS_FAILURE, details);
}

export function successGetBooking(details: CheckInBookedDetail) {
  return typedAction(CHECKIN_DETAILS_SUCCESS, details);
}

// Reservation Status
export function showFloatingReservation() {
  return typedAction(SHOW_RESERVATION_STATUS, "");
}
export function hideFloatingReservation() {
  return typedAction(HIDE_RESERVATION_STATUS, "");
}

// feedback Submit
export function setFeedbackSubmitted() {
  return typedAction(FEEDBACK_SUBMITTED, "");
}

export type CheckInActions = ReturnType<
  | typeof makeBooking
  | typeof failedBooking
  | typeof successBooking
  | typeof getWaitTimes
  | typeof successWaitTimes
  | typeof failedWaitTimes
  | typeof cancelBooking
  | typeof failedCancelBooking
  | typeof successCancelBooking
  | typeof editBooking
  | typeof failedEditbooking
  | typeof successEditBooking
  | typeof getBooking
  | typeof failedGetBooking
  | typeof successGetBooking
  | typeof clearCheckInState
  | typeof storeBookingDetails
  | typeof showFloatingReservation
  | typeof hideFloatingReservation
  | typeof setFeedbackSubmitted
>;
