import { produce } from "immer";
import { OfferActions } from "./offerActions";
import {
  CLEAR_USE_OFFER,
  GET_OFFERS_ERROR,
  GET_OFFERS_REQUEST,
  GET_OFFERS_SUCCESS,
  OFFER_VALIDITY_ERROR,
  OFFER_VALIDITY_REQUEST,
  OFFER_VALIDITY_SUCCESS,
  USE_OFFER_ERROR,
  USE_OFFER_REQUEST,
  USE_OFFER_SUCCESS,
  SET_OFFER_DATA,
  CLEAR_OFFER_DATA,
  CLEAR_VALIDITY_DATA,
  CLEAR_OFFERS,
  AVAIL_WALLER_AMOUNT,
  GET_CHECK_OFFERS_REQUEST,
  GET_CHECK_OFFERS_SUCCESS,
  GET_CHECK_OFFERS_ERROR,
  CLEAR_CHECK_OFFERS,
  APPLIED_OFFERS_REQUEST,
  APPLIED_OFFERS_SUCCESS,
  APPLIED_OFFERS_ERROR,
  CLEAR_APPLIED_OFFERS,
 
} from "./offferConstants";
import { OfferState } from "./offferModels";

const initialCheckInState: OfferState = {
  offers: [],
  offersLoading: false,

  // Offer Validity
  offerId: "",
  offerValidFailure: false,
  offerValidSuccess: false,
  offerValidLoading: false,

  // Use Offer
  useOfferFailure: false,
  useOfferLoading: false,
  useOfferSuccess: false,
  selectedOffer: null,
  offerValidityError: null,
  availWalletAmount: true,
  //redeemedWalletAmt: null,

  checkOfferFailure: false,
  checkOfferSuccess: false,
  checkOfferLoading: false,
  checkofferresult: { offers: [], quantity: "1" },

  appliedOfferFailure: false,
  appliedOfferSuccess: false,
  appliedOfferLoading: false,
  appliedOfferResult: { appliedOffers: [] },
};

export default function paymentReducer(
  state: OfferState = initialCheckInState,
  action: OfferActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Get Offers
      case GET_OFFERS_REQUEST:
        draft.offersLoading = true;
        break;
      case GET_OFFERS_SUCCESS:
        draft.offers = action.payload;
        draft.offersLoading = false;
        break;
      case GET_OFFERS_ERROR:
        draft.offersLoading = false;
        break;
      case CLEAR_OFFERS:
        draft.offers = [];
        break;
      // Offer Validity
      case OFFER_VALIDITY_REQUEST:
        draft.offerId = action.payload.offerId;
        draft.offerValidFailure = false;
        draft.offerValidSuccess = false;
        draft.offerValidLoading = true;
        break;
      case OFFER_VALIDITY_SUCCESS:
        draft.offerValidSuccess = true;
        draft.offerValidLoading = false;
        break;
      case OFFER_VALIDITY_ERROR:
        draft.offersLoading = false;
        draft.offerValidFailure = true;
        draft.offerValidLoading = false;
        draft.offerValidityError = action.payload;
        break;
      case CLEAR_VALIDITY_DATA:
        draft.offerValidSuccess = false;
        draft.offerValidFailure = false;
        draft.offerValidLoading = false;
        break;
      // Use Offer
      case USE_OFFER_REQUEST:
        draft.useOfferLoading = true;
        draft.useOfferSuccess = false;
        draft.useOfferFailure = false;
        break;
      case USE_OFFER_SUCCESS:
        draft.useOfferLoading = false;
        draft.useOfferSuccess = true;
        break;
      case USE_OFFER_ERROR:
        draft.useOfferLoading = false;
        draft.useOfferFailure = true;
        break;
      case CLEAR_USE_OFFER:
        draft.useOfferLoading = false;
        draft.useOfferFailure = false;
        draft.useOfferSuccess = false;
        break;
      case SET_OFFER_DATA:
       
        draft.selectedOffer = action.payload;
        break;

      case CLEAR_OFFER_DATA:
        draft.selectedOffer = null;
        draft.offerValidityError = null;
        draft.useOfferLoading = false;
        draft.useOfferFailure = false;
        draft.useOfferSuccess = false;
        draft.offerValidSuccess = false;
        draft.offerValidFailure = false;
        draft.offerValidLoading = false;
        break;
      
      // case REDEEMED_WALLER_AMOUNT:
      //   draft.redeemedWalletAmt =action.payload;
      //   break;

      case GET_CHECK_OFFERS_REQUEST:
        draft.checkOfferFailure = false;
        draft.checkOfferSuccess = false;
        draft.checkOfferLoading = true;
        break;
      case GET_CHECK_OFFERS_SUCCESS:
        draft.checkofferresult = action.payload;
        draft.checkOfferSuccess = true;
        draft.checkOfferLoading = false;
        break;
      case GET_CHECK_OFFERS_ERROR:
        draft.offersLoading = false;
        draft.checkOfferFailure = true;
        draft.checkOfferLoading = false;
        break;
      case CLEAR_CHECK_OFFERS:
        draft.checkOfferSuccess = false;
        draft.checkOfferFailure = false;
        draft.checkOfferLoading = false;
        break;

      case APPLIED_OFFERS_REQUEST:
        draft.appliedOfferFailure = false;
        draft.appliedOfferSuccess = false;
        draft.appliedOfferLoading = true;
        break;

      case APPLIED_OFFERS_SUCCESS:
        // draft.appliedOfferResult= action.payload;
        draft.appliedOfferSuccess = true;
        draft.appliedOfferFailure = false;
        break;
      case APPLIED_OFFERS_ERROR:
        draft.appliedOfferLoading = false;
        draft.appliedOfferFailure = true;
        draft.appliedOfferLoading = false;
        break;
      case CLEAR_APPLIED_OFFERS:
        draft.appliedOfferSuccess = false;
        draft.appliedOfferFailure = false;
        draft.appliedOfferLoading = false;
        break;
        case AVAIL_WALLER_AMOUNT:
          draft.availWalletAmount = action.payload;
          break;
    }
  });
}
