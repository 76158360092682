import { AxiosResponse } from "axios";
import { put, call, takeLatest, throttle } from "redux-saga/effects";
import { MetaResult } from "../auth/AuthModels";
import {
  errorgetOffers,
  errorOfferVality,
  errorUseOffer,
  OfferActions,
  successgetOffers,
  successOfferVality,
  successUseOffer,
  successCheckOffers,
  errorCheckOffers,
  successAppliedOffers,
  errorAppliedOffers,
} from "./offerActions";
import {
  checkOfferValidityAPI,
  getOffersAPI,
  useOfferAPI,
  checkOfferAPI,
  appliedOffersAPI,
} from "./offferAPI";
import {
  GET_OFFERS_REQUEST,
  OFFER_VALIDITY_REQUEST,
  USE_OFFER_REQUEST,
  GET_CHECK_OFFERS_REQUEST,
  APPLIED_OFFERS_REQUEST,
} from "./offferConstants";
import {
  Offer,
  OfferValidityRequestData,
  UseOfferRequestData,
  offerRequestBody,
  QuantiyBasedOfferRequest,
  AppliedoffersRequest,
  checkoffer,
} from "./offferModels";
import { decryptJson } from "common/utils/react-ec-utils";

function* getOffersSaga(action: OfferActions) {
  try {
    const data = action.payload as offerRequestBody;
    const response: AxiosResponse = yield call(getOffersAPI, data);
    if (response.status === 200) {
      yield put(successgetOffers(response.data as Offer[]));
    } else {
      yield put(errorgetOffers());
    }
  } catch (err) {
    // console.log(err);
    yield put(errorgetOffers());
  }
}

function* checkOfferValiditySaga(action: OfferActions) {
  try {
    const response: AxiosResponse = yield call(
      checkOfferValidityAPI,
      action.payload as OfferValidityRequestData
    );

    if (response.status === 200) {
      const decryptedData = decryptJson(response.data.encryptedData as MetaResult) 
      const result = decryptedData;
      if (result.metaDataInfo.responseCode == "SUCCESS") {
        yield put(successOfferVality());
      } else {
        yield put(errorOfferVality(response.data.metaDataInfo.responseMessage));
      }
    } else {
      yield put(errorOfferVality(response.data.metaDataInfo.responseMessage));
    }
  } catch (err) {
    console.log(err);
    yield put(errorOfferVality("Something went wrong!!"));
  }
}

function* useOfferSaga(action: OfferActions) {
  try {
    const response: AxiosResponse = yield call(
      useOfferAPI,
      action.payload as UseOfferRequestData
    );

    if (response.status === 200) {
      const decryptedData = decryptJson(response.data.encryptedData)
      const result = decryptedData as MetaResult;
      if (result.metaDataInfo.responseCode == "SUCCESS") {
        yield put(successUseOffer());
      } else {
        yield put(errorUseOffer());
      }
    } else {
      yield put(errorUseOffer());
    }
  } catch (err) {
    console.log(err);
    yield put(errorUseOffer());
  }
}
function* checkOfferSaga(action: OfferActions) {
  try {
    const response: AxiosResponse = yield call(
      checkOfferAPI,
      action.payload as QuantiyBasedOfferRequest
    );

    if (response.status === 200) {
      const decryptedData = decryptJson(response.data.encryptedText)
      yield put(
        successCheckOffers(
          decryptedData as checkoffer[],
          (action.payload as QuantiyBasedOfferRequest).quantity
        )
      );
    } else {
      yield put(errorCheckOffers());
    }
  } catch (err) {
    //console.log(err);
    yield put(errorCheckOffers());
  }
}

function* appliedOfferSage(action: OfferActions) {
  try {
    const response: AxiosResponse = yield call(
      appliedOffersAPI,
      action.payload as AppliedoffersRequest
    );

    if (response.status === 200) {
      yield put(successAppliedOffers());
    } else {
      yield put(errorAppliedOffers());
    }
  } catch (err) {
    yield put(errorAppliedOffers());
  }
}

export default function* paymentSaga() {
  yield throttle(5000, GET_OFFERS_REQUEST, getOffersSaga);
  yield throttle(5000, OFFER_VALIDITY_REQUEST, checkOfferValiditySaga);
  yield throttle(5000, USE_OFFER_REQUEST, useOfferSaga);
  yield throttle(5000, GET_CHECK_OFFERS_REQUEST, checkOfferSaga);
  yield throttle(5000, APPLIED_OFFERS_REQUEST, appliedOfferSage);
}
