export const CLEAR_CHECKIN_STATE = 'CLEAR_CHECKIN_STATE';
// Make Booking
export const CHECKIN_REQUEST = 'CHECKIN_REQUEST';
export const CHECKIN_STORE = 'CHECKIN_STORE';
export const CHECKIN_SUCCESS = 'CHECKIN_SUCCESS';
export const CHECKIN_FAILURE = 'CHECKIN_FAILURE';

// Get WaitTime
export const WAITTIME_REQUEST = 'WAITTIME_REQUEST';
export const WAITTIME_SUCCESS = 'WAITTIME_SUCCESS';
export const WAITTIME_FAILURE = 'WAITTIME_FAILURE';


// Cancel Booking
export const CHECKIN_CANCEL_REQUEST = 'CHECKIN_CANCEL_REQUEST';
export const CHECKIN_CANCEL_SUCCESS = 'CHECKIN_CANCEL_SUCCESS';
export const CHECKIN_CANCEL_FAILURE = 'CHECKIN_CANCEL_FAILURE';

// Update Booking
export const CHECKIN_UPDATE_REQUEST = 'CHECKIN_UPDATE_REQUEST';
export const CHECKIN_UPDATE_SUCCESS = 'CHECKIN_UPDATE_SUCCESS';
export const CHECKIN_UPDATE_FAILURE = 'CHECKIN_UPDATE_FAILURE';


// Get CheckIn Details
export const CHECKIN_DETAILS_REQUEST = 'CHECKIN_DETAILS_REQUEST';
export const CHECKIN_DETAILS_SUCCESS = 'CHECKIN_DETAILS_SUCCESS';
export const CHECKIN_DETAILS_FAILURE = 'CHECKIN_DETAILS_FAILURE';


// RESERVATION STATUS
export const SHOW_RESERVATION_STATUS = 'SHOW_RESERVATION_STATUS';
export const HIDE_RESERVATION_STATUS = 'HIDE_RESERVATION_STATUS';


// Feedback Submitted
export const FEEDBACK_SUBMITTED = 'FEEDBACK_SUBMITTED';
