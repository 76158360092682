import React, { Fragment } from "react";
import "./styles.css";

import { useTransition, animated } from "react-spring";

interface ConfirmationPromptProps {
  question?: any;
  isOpen: boolean;
  submitPrompt?: any;
  InfoImage: any;
  submitText?: any;
  cancelText?: any;
  type?: "alert" | "prompt";
}

function ConfirmationPrompt(props: ConfirmationPromptProps) {
  const {
    isOpen,
    submitPrompt,
    question,
    InfoImage,
    submitText,
    cancelText,
    type,
  } = props;

  const transitions = useTransition(isOpen, null, {
    from: { transform: "translate3d(0,-15px,0)", opacity: 0.4 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-15px,0)", opacity: 0 },
    unique: true,
  });

  const [isLoading, setIsLoading] = React.useState(isOpen);

  React.useEffect(() => {
    setIsLoading(!isOpen)
  }, [isOpen])
  

  return (
    <Fragment>
      <Fragment>
        {isOpen ? <div id="modal-background"></div> : null}
        {transitions.map(({ item, key, props }) =>
          item ? (
            <animated.div className="CPWrapper" key={key}>
              <animated.div className="CPContainer" key={key} style={props}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  {InfoImage != null ? <InfoImage /> : null}
                </div>
                <div className="conf-question"
                  style={{
                    
                  }}
                ><span style={{fontSize:14,color:'black'}}>{question}</span>
                  
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {type == "prompt" && (
                    <span className="conf-cancel"
                      onClick={() => submitPrompt(false)}
                    >
                      {cancelText ?? "No"}
                    </span>
                  )}
                  <span className="conf-accept"
                  style={{ cursor: isLoading ? 'not-allowed' : 'pointer',  }}
                    onClick={() => {
                      !isLoading &&  submitPrompt(true)
                      setIsLoading(true);
                    }}
                  >
                    {submitText ?? "Yes"}
                  </span>
                </div>
              </animated.div>
            </animated.div>
          ) : null
        )}
      </Fragment>
    </Fragment>
  );
}

export default ConfirmationPrompt;
