export const STORE_LOCATION_AFTER_AUTH = "STORE_LOCATION_AFTER_AUTH";
export const CLEAR_LOCATION_AFTER_AUTH = "CLEAR_LOCATION_AFTER_AUTH";

export const STORE_USER = "STORE_USER";
export const CLEAR_USER = "CLEAR_USER";
export const SAVE_GUEST_USER = "SAVE_GUEST_USER";
export const SAVE_SIGNUP_USER = "SAVE_SIGNUP_USER";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";
export const SIGNUP_RESET = "SIGNUP_RESET";

export const SIGNIN_REQUEST = "SIGNIN_REQUEST";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const OTP_STATE_CLEAR = "OTP_STATE_CLEAR";
export const OTP_VERIFICATION_REQUEST = "OTP_VERIFICATION_REQUEST";
export const OTP_VERIFICATION_SUCCESS = "OTP_VERIFICATION_SUCCESS";
export const OTP_VERIFICATION_FAILURE = "OTP_VERIFICATION_FAILURE";

export const MOBILE_VERIFICATION_REQUEST = "MOBILE_VERIFICATION_REQUEST";
export const MOBILE_VERIFICATION_SUCCESS = "MOBILE_VERIFICATION_SUCCESS";
export const MOBILE_VERIFICATION_OTP_REQUIRED =
  "MOBILE_VERIFICATION_OTP_REQUIRED";
export const MOBILE_VERIFICATION_FAILURE = "MOBILE_VERIFICATION_FAILURE";
export const CLEAR_MOBILE_VERIFICATION = "CLEAR_MOBILE_VERIFICATION";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_INITIATE_SUCCESS =
  "RESET_PASSWORD_INITIATE_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
export const SAVE_RESET_PASSWORD_USER = "SAVE_RESET_PASSWORD_USER";
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD";

export const SIGN_OUT = "SIGN_OUT";

export const GET_COUNTRY_CODE_REQUEST = "GET_COUNTRY_CODE_REQUEST";
export const GET_COUNTRY_CODE_SUCCESS = "GET_COUNTRY_CODE_SUCCESS";
export const GET_COUNTRY_CODE_FAILURE = "GET_COUNTRY_CODE_FAILURE";

export const SET_NEW_MOBILE_DETAILS = "SET_NEW_MOBILE_DETAILS";

export const SIGNIN_DATA_EDIT_PROFILE = "SIGNIN_DATA_EDIT_PROFILE";
export const DELETE_SIGNIN_DATA_EDIT_PROFILE =
  "DELETE_SIGNIN_DATA_EDIT_PROFILE";
export const REDEEMED_WALLER_AMOUNT = "REDEEMED_WALLER_AMOUNT";

export const CLEAR_OTP_DEFAULT = 'CLEAR_OTP_DEFAULT';
