import { typedAction } from "../actionTypes";
import { SET_MENU_OPTION, DIALOG_OPEN, ROUTER_PAYLOAD_UPDATER } from "./navigationConstant";
import { MenuOptionType } from "./navigationModels";

export function setMenuOption(option : MenuOptionType) {
  return typedAction(SET_MENU_OPTION, option);
}
export function dialogOpen(payload: boolean) {
  return typedAction(DIALOG_OPEN, payload);
}

export function routerParemUpdater(payload: any) {
  return typedAction(ROUTER_PAYLOAD_UPDATER, payload);
}

export type navigationActions = ReturnType<
|typeof setMenuOption
| typeof routerParemUpdater
>;
