import { produce } from "immer";
import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  SIGNIN_REQUEST,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_SUCCESS,
  OTP_VERIFICATION_FAILURE,
  MOBILE_VERIFICATION_REQUEST,
  MOBILE_VERIFICATION_SUCCESS,
  MOBILE_VERIFICATION_FAILURE,
  OTP_STATE_CLEAR,
  MOBILE_VERIFICATION_OTP_REQUIRED,
  STORE_USER,
  CLEAR_USER,
  SAVE_GUEST_USER,
  SAVE_SIGNUP_USER,
  STORE_LOCATION_AFTER_AUTH,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_REQUEST,
  SIGN_OUT,
  CLEAR_RESET_PASSWORD,
  RESET_PASSWORD_INITIATE_SUCCESS,
  CLEAR_MOBILE_VERIFICATION,
  CLEAR_LOCATION_AFTER_AUTH,
  GET_COUNTRY_CODE_SUCCESS,
  SET_NEW_MOBILE_DETAILS,
  DELETE_SIGNIN_DATA_EDIT_PROFILE,
  REDEEMED_WALLER_AMOUNT,
  CLEAR_OTP_DEFAULT
} from "./AuthConstants";
import { AuthActions } from "./AuthActions";
import { MetaDataInfo, AuthState } from "./AuthModels";

const initialCheckInState: AuthState = {
  // SignIn
  signedIn: false,
  signInLoading: false,
  signInMessage: "",

  // SignUp
  signedUp: false,
  signUpLoading: false,
  signUpMessage: "",

  // User Auth Details
  user: null,
  guestUser: null,
  signUpUser: null,

  // OTP
  otpVerficationLoading: false,
  otpVerifiedSuccess: false,
  otpVerificationMessage: "",

  // Mobile
  mobileVerficationLoading: false,
  mobileVerifiedSuccess: false,
  otpRequired: false,
  mobileVerficationMessage: "",
  mobileVerificationNumber: null,

  // Location
  toLocationAfterAuth: "/",

  // Reset Password
  resetPasswordLoading: false,
  resetPasswordMessage: "",
  resetPasswordInitiateSuccess: false,
  resetPasswordSuccess: false,
  resetPasswordUser: null,
  resetPasswordOtpVerified: false,
  countryCode: null,
  newMobileDetails: null,

  //For Edit Profile
  signInSuccessEditProfile: false,
  signInFailedEditProfile: false,
  signInLoadingEditProfile: false,
  redeemedWalletAmt:null
};

export default function authReducer(
  state: AuthState = initialCheckInState,
  action: AuthActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // SignUp Reducers
      // API Migrated to Mobile Verfication API
      // case SIGNUP_REQUEST:
      //   draft.signedUp = false;
      //   draft.signUpLoading = true;
      //   break;
      // case SIGNUP_SUCCESS:
      //   draft.signedUp = true;
      //   draft.signUpLoading = false;
      //   break;
      // case SIGNUP_FAILURE:
      //   draft.signedUp = false;
      //   draft.signUpLoading = false;
      //   draft.signUpMessage = action.payload.message;
      //   break;
      case STORE_LOCATION_AFTER_AUTH:
        draft.toLocationAfterAuth = action.payload;
        break;
      case CLEAR_LOCATION_AFTER_AUTH:
        draft.toLocationAfterAuth = "/";
        break;
      case SAVE_SIGNUP_USER:
        draft.signUpUser = action.payload;
        break;
      // SignIn Reducers
      case SIGNIN_REQUEST:
        draft.signedIn = false;
        draft.signInLoading = true;
        draft.signInFailedEditProfile = false;
        draft.signInLoadingEditProfile = true;
        draft.signInSuccessEditProfile = false;
        // draft.user = null;
        break;
      case SIGNIN_SUCCESS:
        draft.signedIn = true;
        draft.guestUser = null;
        draft.signInLoading = false;
        draft.user = action.payload;
        draft.signInFailedEditProfile = false;
        draft.signInLoadingEditProfile = false;
        draft.signInSuccessEditProfile = true;
        break;
      case SIGNIN_FAILURE:
        draft.signedIn = false;
        draft.signInLoading = false;
        draft.signInMessage = action.payload.message;
        draft.signInFailedEditProfile = true;
        draft.signInLoadingEditProfile = false;
        draft.signInSuccessEditProfile = false;
        break;
      // User Details
      case STORE_USER:
        draft.user = action.payload;
        // SignIn
        if (action.payload.accessToken && action.payload.refreshToken) {
          draft.signedIn = true;
        }
        break;
      case CLEAR_USER:
        draft.user = null;
        break;
      case SAVE_GUEST_USER:
        draft.guestUser = action.payload;
        break;
      // OTP Verify Reducers
      case CLEAR_OTP_DEFAULT:
        draft.otpVerficationLoading = false;
        draft.otpVerifiedSuccess = false;
        draft.otpVerificationMessage = "";
        break;
      case OTP_VERIFICATION_REQUEST:
        draft.otpVerficationLoading = true;
        draft.otpVerifiedSuccess = false;
        draft.otpVerificationMessage = "";
        break;
      case OTP_VERIFICATION_SUCCESS:
        draft.otpVerficationLoading = false;
        draft.otpVerifiedSuccess = true;
        draft.user = action.payload;
        draft.otpVerificationMessage = "";
        draft.resetPasswordOtpVerified = true;
        break;
      case OTP_VERIFICATION_FAILURE:
        draft.otpVerficationLoading = false;
        draft.otpVerifiedSuccess = false;
        draft.otpVerificationMessage = action.payload.message;
        break;
      case OTP_STATE_CLEAR:
        // Clear OTP Verfication
        draft.otpVerficationLoading = false;
        draft.otpVerifiedSuccess = false;
        draft.otpVerificationMessage = "";
        draft.otpRequired = true;

        // Clear Mobile Verification
        draft.mobileVerifiedSuccess = false;
        draft.mobileVerficationLoading = false;
        draft.mobileVerficationMessage = "";
        break;
      // Mobile Verify Reducers
      case MOBILE_VERIFICATION_REQUEST:
        draft.mobileVerficationLoading = true;
        draft.mobileVerifiedSuccess = false;
        draft.otpRequired = false;
        draft.mobileVerficationMessage = "";
        draft.mobileVerificationNumber = action.payload.mobilePhone;
        break;
      case MOBILE_VERIFICATION_SUCCESS:
        draft.mobileVerficationLoading = false;
        draft.mobileVerificationNumber = "";
        draft.mobileVerifiedSuccess = true;
        draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = '';
        // draft.user = action.payload;
        break;
      case MOBILE_VERIFICATION_OTP_REQUIRED:
        draft.mobileVerficationLoading = false;
        draft.mobileVerifiedSuccess = false;
        draft.otpRequired = true;
        // draft.otpVerficationLoading = true;
        draft.otpVerificationMessage = '';
        break;
      case MOBILE_VERIFICATION_FAILURE:
        // console.log(action);
        draft.mobileVerficationLoading = false;
        draft.mobileVerifiedSuccess = false;
        draft.mobileVerficationMessage = action.payload.message;
        draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = action.payload.message;
        break;
      case CLEAR_MOBILE_VERIFICATION:
        draft.otpRequired = false;
        draft.mobileVerficationLoading = false;
        draft.mobileVerifiedSuccess = false;
        draft.mobileVerficationMessage = "";
        draft.mobileVerificationNumber = "";
        draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = '';
        break;
      case RESET_PASSWORD_REQUEST:
        draft.resetPasswordLoading = true;
        draft.resetPasswordMessage = "";
        draft.resetPasswordSuccess = false;
        draft.resetPasswordInitiateSuccess = false;
        draft.resetPasswordUser = action.payload;
        // Store Mobile Number for OTP Verification Flow
        if (action.payload.mobilePhone) {
          draft.mobileVerificationNumber = action.payload.mobilePhone;
        }
        // draft.otpVerficationLoading = true;
        draft.otpVerificationMessage = '';
        break;
      case RESET_PASSWORD_SUCCESS:
        draft.resetPasswordLoading = false;
        draft.resetPasswordSuccess = true;
        // draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = '';
        break;
      case RESET_PASSWORD_INITIATE_SUCCESS:
        draft.resetPasswordLoading = false;
        draft.resetPasswordMessage = action.payload;
        draft.resetPasswordInitiateSuccess = true;
        break;
      case RESET_PASSWORD_FAILURE:
        draft.resetPasswordLoading = false;
        draft.resetPasswordSuccess = false;
        draft.resetPasswordMessage = action.payload;
        // draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = action.payload;
        break;
      case CLEAR_RESET_PASSWORD:
        draft.resetPasswordLoading = false;
        draft.resetPasswordSuccess = false;
        draft.resetPasswordInitiateSuccess = false;
        draft.resetPasswordMessage = "";
        draft.resetPasswordOtpVerified = false;
        draft.otpVerficationLoading = false;
        draft.otpVerificationMessage = '';
        break;
      case SIGN_OUT:
        localStorage.clear();
        draft.signedIn = false;
        draft.guestUser = null;
        draft.user = null;
        draft.toLocationAfterAuth = "/";
        break;
      case GET_COUNTRY_CODE_SUCCESS:
        draft.countryCode = action.payload;
        break;
      case SET_NEW_MOBILE_DETAILS:
        draft.newMobileDetails = action.payload;
        break;

      // Edit Profile SignIn

      case DELETE_SIGNIN_DATA_EDIT_PROFILE:
        draft.signInFailedEditProfile = false;
        draft.signInLoadingEditProfile = false;
        draft.signInSuccessEditProfile = false;
        break;
        case REDEEMED_WALLER_AMOUNT:
          draft.redeemedWalletAmt = action.payload;
          break;
      // case SAVE_RESET_PASSWORD_USER:
      //   draft.resetPasswordUser=action.payload;
      //   break;
      default:
        break;
    }
  });
}
