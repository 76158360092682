import { produce } from "immer";
import { navigationActions } from "./navigationActions";
import { SET_MENU_OPTION, DIALOG_OPEN, ROUTER_PAYLOAD_UPDATER } from "./navigationConstant";
import { InitialNavigationState, MenuOptionType } from "./navigationModels";

const initialNavigationState: InitialNavigationState = {
  currentMenuOption: "Menu",
  modal_open: false,
  routerPayloads:null,
};
export default function tinyURLReducer(
  state: InitialNavigationState = initialNavigationState,
  action: navigationActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case SET_MENU_OPTION:
        draft.currentMenuOption = action.payload as MenuOptionType;
        break;
        
      case DIALOG_OPEN:
        draft.modal_open = action.payload;
        break;

      case ROUTER_PAYLOAD_UPDATER:
        draft.routerPayloads = action.payload;
        break;  
        
      default:
        break;
    }
  });
}
