import API from "common/api";
import {
  RestaurantMenuRequest,
  RestaurantsRequestParams,
  SubmitFeedbackDetail,
} from "./restaurantModels";
import { encryptJson } from "common/utils/react-ec-utils";

// Get Restaurants
export function getRestaurants(locationParams: RestaurantsRequestParams) {
  const data = encryptJson(locationParams)
  return API({
    method: "post",
    url: "/customer/findMerchant/v2",
    data: {"data": data},
  });
}

// Get Restaurant Details
export function getRestaurantDetails(slugId: string) {
  const encryptedData = encryptJson({ slug: slugId }) 
  return API({
    method: 'post',
    url: '/merchants/locations/slug',
    data: { data: encryptedData },
  });
  // return API({
  //   method: "get",
  //   url: "/merchants/info/" + slugId,
  // });
}

//  Get Restaurant details using location Id
export function getRestaurantDetailsbyLocationId(locationId: string) {
  const encryptedData = encryptJson({ staffId: null, locationId: locationId })
  return API({
    method: 'post',
    url: '/merchants/locations/id',
    data: { data: encryptedData },
  });

  // return API({
  //   method: "get",
  //   url: `/merchants/location/${locationId}/details`,
  // });
}

export function getRestaurantMenuDetailsByEventDate(details: RestaurantMenuRequest) {
  const data = encryptJson({ eventSlug: details?.eventSlug, locationId: details?.locationId, orderType: details?.type })
  return API({
    method: "post",
    url: `/merchants/locations/event-order-menu/v2`,
    data:{'data':data}
  });
}

export function getRestaurantMenuDetails(details: RestaurantMenuRequest) {
  const updatedDetails = {
    ...details,
    visibleTo: Array.isArray(details.visibleTo) ? details.visibleTo : [details.visibleTo],
  };

  const data = encryptJson(updatedDetails);

  return API({
    method: "post",
    url: "/merchants/menu/v2",
    data: { "data": data },
  });
}


export function getFeedbackDetails() {
  return API({
    method: "get",
    url: "/customer/feedback/v2",
  });
}

export function submitFeedback(SubmitFeedbackDetails: SubmitFeedbackDetail) {
  const data=encryptJson(SubmitFeedbackDetails)
  return API({
    method: "post",
    url: "/customer/feedback/v2",
    data: {"data":data},
  });
}

export function getmasterRestaurantHomePageDetails(slug: string) {
  const data=encryptJson({"slug":slug})
  return API({
    method: "post",
    url: `/merchants/details/v2`,
    data:{'data': data}
  });
}

export function getRestaurantTableDetails(locationId: string) {
  let payload={"locationId":locationId}
  const data = encryptJson(payload);
  return API({
    method: "post",
    url: '/merchants/locations/reservations/table-details/v2',
    data:{'data': data}
  });
}

export function getSearchResult(
  lat: string,
  lng: string,
  keyword: string,
  page: number
) {
  return API({
    method: "get",
    url: `/search?text=${keyword}&page=${page}&size=25&latitude=${lat}&longitude=${lng}`,
  });
}

export function getExactSearchResult(
  lat: string,
  lng: string,
  keyword: string
) {
  return API({
    method: "get",
    url: `/search?text=${keyword}&isExactMatch=1&latitude=${lat}&longitude=${lng}`,
  });
}
