export const AVAIL_WALLER_AMOUNT = "AVAIL_WALLER_AMOUNT";


export const GET_OFFERS_REQUEST = "GET_OFFERS_REQUEST";
export const GET_OFFERS_SUCCESS = "GET_OFFERS_SUCCESS";
export const GET_OFFERS_ERROR = "GET_OROFFERS_ERROR";
export const CLEAR_OFFERS = "CLEAR_OFFERS";

export const OFFER_VALIDITY_REQUEST = "OFFER_VALIDITY_REQUEST";
export const OFFER_VALIDITY_SUCCESS = "OFFER_VALIDITY_SUCCESS";
export const OFFER_VALIDITY_ERROR = "OFFER_VALIDITY_ERROR";

export const USE_OFFER_REQUEST = "USE_OFFER_REQUEST";
export const USE_OFFER_SUCCESS = "USE_OFFER_SUCCESS";
export const USE_OFFER_ERROR = "USE_OFFER_ERROR";
export const CLEAR_USE_OFFER = "CLEAR_USE_OFFER";

export const SET_OFFER_DATA = "SET_OFFER_DATA";
export const CLEAR_OFFER_DATA = "CLEAR_OFFER_DATA";
export const CLEAR_VALIDITY_DATA = "CLEAR_VALIDITY_DATA";


//export const REDEEMED_WALLER_AMOUNT = "REDEEMED_WALLER_AMOUNT";

export const GET_CHECK_OFFERS_REQUEST = "GET_CHECK_OFFERS_REQUEST";
export const GET_CHECK_OFFERS_SUCCESS = "GET_CHECK_OFFERS_SUCCESS";
export const GET_CHECK_OFFERS_ERROR = "GET_CHECK_OFFERS_ERROR";
export const CLEAR_CHECK_OFFERS = "CLEAR_CHECK_OFFERS";

export const APPLIED_OFFERS_REQUEST = "APPLIED_OFFERS_REQUEST";
export const APPLIED_OFFERS_SUCCESS = "APPLIED_OFFERS_SUCCESS";
export const APPLIED_OFFERS_ERROR = "APPLIED_OFFERS_ERROR";
export const CLEAR_APPLIED_OFFERS ="CLEAR_APPLIED_OFFERS";
