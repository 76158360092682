import API from "common/api";
import { Cart } from "./cartModels";
import { encryptJson } from "common/utils/react-ec-utils";

export function placeOrder(cartData: Cart) {
    const data = encryptJson(cartData);

    return API({
        method: "post",
        url: "/order/customer/createOrder",
        data: { "data": data},
    });
}

