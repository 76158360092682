import { encryptJson } from "common/utils/react-ec-utils";
import {
  CheckInBookingDetails,
  CheckInBookingForm,
  WaitTimeRestaurantDetail,
  CancelCheckInDetails,
  CheckInSuccessResult,
} from "./checkInModels";
import API from "common/api";
  
// Making Booking
export function makeBooking(CheckInDetails: CheckInBookingDetails) {
  const data = encryptJson(CheckInDetails);

  return API({
    method: "post",
    url: "/customer/reservations",
    params:{data}
  });
}

// Get WaitTime
export function getWaitTimes(detail: WaitTimeRestaurantDetail) {
  let payload = { "locationId" : detail.locationID};
  const data = encryptJson(payload);
  return API({
    method: "post",
    url:`/merchants/v2/locations/wait-times`,
    param:data
  });
}

// Cancel Booking
export function cancelBooking(CheckInDetails: CancelCheckInDetails) {
  let payload = { "locationId" : CheckInDetails.locationID, "reservationId":CheckInDetails.reservationID};
  const data = encryptJson(payload);
  return API({
    method: "put",
    url: `/customer/cancel-table?user=customer`,
    data: { "data": data}
  });
}

// Update Booking
export function updateBooking(CheckInDetails: CheckInBookingDetails) {
  return API({
    method: "post",
    url: "/merchants/" + CheckInDetails.locationId + "/reservations",
    data: CheckInDetails,
  });
}

// Get Booking
export function getBooking(reservationID: string) {
  let payload = { "reservationId":reservationID};
  const data = encryptJson(payload);
  return API({
    method: "post",
    url: "/customer/reservationId/v2",
    data: { "data": data}
  });
}
