export const RAZOR_PAY_TRANSFER_ORDER = "RAZOR_PAY_TRANSFER_ORDER";
export const RAZOR_PAY_TRANSFER_ORDER_SUCCESS =
  "RAZOR_PAY_TRANSFER_ORDER_SUCCESS";
export const RAZOR_PAY_TRANSFER_ORDER_FAILURE =
  "RAZOR_PAY_TRANSFER_ORDER_FAILURE";
export const RAZOR_PAY_TRANSFER_ORDER_ERROR = "RAZOR_PAY_TRANSFER_ORDER_ERROR";
export const RAZOR_PAY_TRANSFER_ORDER_CLEAR = "RAZOR_PAY_TRANSFER_ORDER_CLEAR";
export const CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS =
  "CLEAR_RAZOR_PAY_TRANSFER_ORDER_SUCCESS";

export const FIRST_DATA_PAYMENT_REQUEST = "FIRST_DATA_PAYMENT_REQUEST";
export const FIRST_DATA_PAYMENT_SUCCESS = "FIRST_DATA_PAYMENT_SUCCESS";
export const FIRST_DATA_PAYMENT_FAILURE = "FIRST_DATA_PAYMENT_FAILURE";
export const CLEAR_FIRST_DATA_PAYMENT = "CLEAR_FIRST_DATA_PAYMENT";

// STRIPE
export const STRIPE_PAYMENT_REQUEST = "STRIPE_PAYMENT_REQUEST";
export const STRIPE_PAYMENT_SUCCESS = "STRIPE_PAYMENT_SUCCESS";
export const STRIPE_PAYMENT_FAILURE = "STRIPE_PAYMENT_FAILURE";
export const STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS =
  "STRIPE_PAYMENT_TOKEN_RETRIEVE_SUCCESS";
export const CLEAR_STRIPE_PAYMENT = "CLEAR_STRIPE_PAYMENT";
export const STRIPE_CAPTURE_PAYMENT = "STRIPE_CAPTURE_PAYMENT";

// Offline Payment
export const OFFLINE_PAYMENT_REQUEST = "OFFLINE_PAYMENT_REQUEST";
export const OFFLINE_PAYMENT_SUCCESS = "OFFLINE_PAYMENT_SUCCESS";
export const OFFLINE_PAYMENT_FAILURE = "OFFLINE_PAYMENT_FAILURE";
export const RESET_OFFLINE_PAYMENT_DATA = "RESET_OFFLINE_PAYMENT_DATA";

// Tender type constants
export const paymentTenderTypes = {
  CNP: "CNP",
  PaymentLinks: "PAYMENT_LINKS",
  BharatQR: "BHARAT_QR",
};

// Payment currencies constants
export const paymentCurrencies = {
  USD: "USD",
  INR: "INR",
};


export const CNP_DATA_PAYMENT_REQUEST = "CNP_DATA_PAYMENT_REQUEST";
export const CNP_DATA_PAYMENT_SUCCESS = "CNP_DATA_PAYMENT_SUCCESS";
export const CNP_DATA_PAYMENT_FAILURE = "CNP_DATA_PAYMENT_FAILURE";
export const CLEAR_CNP_DATA_PAYMENT = "CLEAR_CNP_DATA_PAYMENT";