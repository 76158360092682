import { typedAction } from "../actionTypes";
import { LocationState } from "../location/locationModels";
import {
  CLEAR_GET_DELIVERY_CHARGE,
  CLEAR_VALET_LOCATION,
  DUNZO_ORDER_CREATED,
  DUNZO_RUNNER_ASSIGNED,
  GET_DELIVERY_CHARGE_FAILURE,
  GET_DELIVERY_CHARGE_INITATED,
  GET_DELIVERY_CHARGE_SUCCEESS,
  GET_DUNZO_TRACKING_CLEAR,
  GET_DUNZO_TRACKING_FAILED,
  GET_DUNZO_TRACKING_INTIATED,
  GET_DUNZO_TRACKING_SUCCESS,
  GET_VALET_LOCATION,
  VALET_LOCATION_FAILED,
  VALET_LOCATION_SUCCESS,
} from "./DeliveryConstants";
import {
  DeliveryChargeRequestBody,
  DeliveryQuotationDetails,
  DunzoTrackingDetails,
  getDeliveryDetails,
} from "./DeliveryModels";

export function getDeliveryChargeInitiated(details: DeliveryChargeRequestBody) {
  return typedAction(GET_DELIVERY_CHARGE_INITATED, details);
}

export function getDeliveryChargeSuccess(
  deliveryQuotationDetails: DeliveryQuotationDetails
) {
  return typedAction(GET_DELIVERY_CHARGE_SUCCEESS, deliveryQuotationDetails);
}

export function getDeliveryChargeFailed() {
  return typedAction(GET_DELIVERY_CHARGE_FAILURE, "");
}

export function clearGetDeliveryCharge() {
  return typedAction(CLEAR_GET_DELIVERY_CHARGE, "");
}

export function getDunzoTrackingInitiated(
  getDeliveryDetails: getDeliveryDetails
) {
  return typedAction(GET_DUNZO_TRACKING_INTIATED, getDeliveryDetails);
}

export function getDunzoTrackingSuccess(trackingDetails: DunzoTrackingDetails) {
  return typedAction(GET_DUNZO_TRACKING_SUCCESS, trackingDetails);
}

export function getDunzoTrackingFailed() {
  return typedAction(GET_DUNZO_TRACKING_FAILED, "");
}

export function clearDunzoTracking() {
  return typedAction(GET_DUNZO_TRACKING_CLEAR, "");
}

export function dunzoRunnerAssigned(trackingDetails: DunzoTrackingDetails) {
  return typedAction(DUNZO_RUNNER_ASSIGNED, trackingDetails);
}

export function dunzoorderCreated(trackingDetails: DunzoTrackingDetails) {
  return typedAction(DUNZO_ORDER_CREATED, trackingDetails);
}

export function getValetLocation(deliveryStaffId: string) {
  return typedAction(GET_VALET_LOCATION, deliveryStaffId);
}

export function getValetLocationSuccess(valetLocation: LocationState) {
  return typedAction(VALET_LOCATION_SUCCESS, valetLocation);
}

export function getValetLocationFailure() {
  return typedAction(VALET_LOCATION_FAILED, "");
}

export function clearValetLocation() {
  return typedAction(CLEAR_VALET_LOCATION, "");
}

export type DeliveryActions = ReturnType<
  | typeof getDeliveryChargeInitiated
  | typeof getDeliveryChargeSuccess
  | typeof getDeliveryChargeFailed
  | typeof clearGetDeliveryCharge
  | typeof getDunzoTrackingInitiated
  | typeof getDunzoTrackingSuccess
  | typeof getDunzoTrackingFailed
  | typeof clearDunzoTracking
  | typeof dunzoRunnerAssigned
  | typeof dunzoorderCreated
  | typeof getValetLocation
  | typeof getValetLocationSuccess
  | typeof getValetLocationFailure
  | typeof clearValetLocation
>;
