// RESERVATION STATUS
export const SHOW_RESERVATION_STATUS = "SHOW_RESERVATION_STATUS";
export const HIDE_RESERVATION_STATUS = "HIDE_RESERVATION_STATUS";

// CART ITEM
export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const START_EMPTY_CART = "START_EMPTY_CART";

export const SET_GUEST_MOBILE_VERIFIED = "SET_GUEST_MOBILE_VERIFIED";

export const MODIFY_CART_ITEM = "MODIFY_CART_ITEM";

// Update ItemOptions
export const INCREMENT_CART_ITEM = "INCREMENT_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const DECREMENT_CART_ITEM = "DECREMENT_CART_ITEM";

export const SAVE_ITEM_COMMENTS = "SAVE_ITEM_COMMENTS";

export const START_CUSTOMISE_ITEM = "START_CUSTOMISE_ITEM";
export const CLEAR_CUSTOMISE_ITEM = "CLEAR_CUSTOMISE_ITEM";
export const UPDATE_ITEM_CUSTOMISATION = "UPDATE_ITEM_CUSTOMISATION";
export const SAVE_ITEM_CUSTOMISATION = "SAVE_ITEM_CUSTOMISATION";
export const FINISH_ITEM_CUSTOMISATION = "FINISH_ITEM_CUSTOMISATION";

// Customisation
export const ADD_CUSTOMISE_INDIVIDUALLY = "ADD_CUSTOMISE_INDIVIDUALLY";
export const REMOVE_CUSTOMISE_INDIVIDUALLY = "REMOVE_CUSTOMISE_INDIVIDUALLY";
export const LOAD_SAVED_INDIVIDUAL_CUSTOMISATION =
  "LOAD_SAVED_INDIVIDUAL_CUSTOMISATION";
export const REMOVE_LAST_ITEM_INSTANCE = "REMOVE_LAST_ITEM_INSTANCE";

// PLACE ORDER
export const SAVE_ORDER_DETAILS = "SAVE_ORDER_DETAILS";
export const REMOVE_CART_TIP = "REMOVE_CART_TIP";
export const PLACE_ORDER_REQUEST = "PLACE_ORDER_REQUEST";
export const PLACE_ORDER_SUCCESS = "PLACE_ORDER_SUCCESS";
export const PLACE_ORDER_FAILURE = "PLACE_ORDER_FAILURE";
export const CLEAR_PLACE_ORDER = "CLEAR_PLACE_ORDER";
export const CLEAR_PLACE_ORDER_SUCCESS = "CLEAR_PLACE_ORDER_SUCCESS";
export const ADD_DELIVERY_ADDRESS = "ADD_DELIVERY_ADDRESS";

// Floating Cart
export const SHOW_FLOATING_CART = "SHOW_FLOATING_CART";
export const HIDE_FLOATING_CART = "HIDE_FLOATING_CART";

// Max Count Prompt
export const SHOW_ITEM_MAX_COUNT_PROMPT = "SHOW_ITEM_MAX_COUNT_PROMPT";
export const HIDE_ITEM_MAX_COUNT_PROMPT = "HIDE_ITEM_MAX_COUNT_PROMPT";

export const SET_PICKUP_TIME = "SET_PICKUP_TIME";

export const STORE_CART_RESTAURANT_DETAILS = "STORE_CART_RESTAURANT_DETAILS";

// Cart Auth
export const SET_CART_AUTH_PENDING = "SET_CART_AUTH_PENDING";

//Delivery Distance
export const SET_DELIVERY_ORDER_DISTANCE = "SET_DELIVERY_ORDER_DISTANCE";
export const CLEAR_DELIVERY_ORDER_DISTANCE = "CLEAR_DELIVERY_ORDER_DISTANCE";

// Running Cart
export const SET_RUNNING_CART = "SET_RUNNING_CART";
export const CLEAR_RUNNING_CART = "CLEAR_RUNNING_CART";

//update event order
export const UPDATE_EVENT_ORDER = "UPDATE_EVENT_ORDER";
export const UPDATE_EVENT_ORDER_SLOT = "UPDATE_EVENT_ORDER_SLOT";