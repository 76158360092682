import {
  SIGNIN_REQUEST,
  SIGNUP_REQUEST,
  SAVE_GUEST_USER,
  SIGNUP_FAILURE,
  SIGNUP_SUCCESS,
  SIGNIN_FAILURE,
  SIGNIN_SUCCESS,
  SIGNUP_RESET,
  OTP_VERIFICATION_REQUEST,
  OTP_VERIFICATION_FAILURE,
  OTP_VERIFICATION_SUCCESS,
  MOBILE_VERIFICATION_REQUEST,
  MOBILE_VERIFICATION_FAILURE,
  MOBILE_VERIFICATION_SUCCESS,
  OTP_STATE_CLEAR,
  MOBILE_VERIFICATION_OTP_REQUIRED,
  STORE_USER,
  CLEAR_USER,
  SAVE_SIGNUP_USER,
  STORE_LOCATION_AFTER_AUTH,
  CLEAR_LOCATION_AFTER_AUTH,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SIGN_OUT,
  CLEAR_RESET_PASSWORD,
  RESET_PASSWORD_INITIATE_SUCCESS,
  CLEAR_MOBILE_VERIFICATION,
  GET_COUNTRY_CODE_SUCCESS,
  GET_COUNTRY_CODE_REQUEST,
  SET_NEW_MOBILE_DETAILS,
  DELETE_SIGNIN_DATA_EDIT_PROFILE,
  REDEEMED_WALLER_AMOUNT,
  CLEAR_OTP_DEFAULT,
} from "./AuthConstants";
import { typedAction } from "../actionTypes";
import {
  SignUpForm,
  MetaResult,
  SignUpData,
  SignInForm,
  MessageResult,
  UserDetails,
  OTPVerificationDetails,
  OTPVerificationResult,
  MobileVerificationResult,
  MobileVerificationDetails,
  GuestUser,
  SignUpUser,
  ResetPassword,
} from "./AuthModels";
import { strict } from "assert";

// store User
export function storeUser(details: UserDetails) {
  return typedAction(STORE_USER, details);
}

// clear user
export function clearUser() {
  return typedAction(CLEAR_USER, "");
}

// // SignUp
// export function signUp(details: SignUpData) {
//   return typedAction(SIGNUP_REQUEST, details);
// }

// export function failedSignUp(details: MessageResult) {
//   // Replace any with Network Data Fail Data format
//   return typedAction(SIGNUP_FAILURE, details);
// }

// export function successSignUp(details: MessageResult) {
//   return typedAction(SIGNUP_SUCCESS, details);
// }

// export function signUpReset() {
//   return typedAction(SIGNUP_RESET);
// }

// Store Location After Auth
export function storeLocationAfterAuth(details: string) {
  return typedAction(STORE_LOCATION_AFTER_AUTH, details);
}

// Clear Location After Auth
export function clearLocationAfterAuth() {
  return typedAction(CLEAR_LOCATION_AFTER_AUTH, "");
}

// SignIn
export function signIn(details: SignInForm) {
  return typedAction(SIGNIN_REQUEST, details);
}

export function failedSignIn(details: MessageResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(SIGNIN_FAILURE, details);
}

export function successSignIn(details: UserDetails) {
  return typedAction(SIGNIN_SUCCESS, details);
}

// Mobile
export function verifyMobile(details: MobileVerificationDetails) {
  return typedAction(MOBILE_VERIFICATION_REQUEST, details);
}

export function failedVerifyMobile(details: MessageResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(MOBILE_VERIFICATION_FAILURE, details);
}

export function verifyMobileOTPRequired(details: MessageResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(MOBILE_VERIFICATION_OTP_REQUIRED, details);
}

export function successVerifyMobile(details: MobileVerificationResult) {
  return typedAction(MOBILE_VERIFICATION_SUCCESS, {
    id: details.userID,
  } as UserDetails);
}

export function clearVerifyMobile() {
  return typedAction(CLEAR_MOBILE_VERIFICATION, "");
}

// OTP
export function verifyOTP(details: OTPVerificationDetails) {
  return typedAction(OTP_VERIFICATION_REQUEST, details);
}

export function failedVerifyOTP(details: MessageResult) {
  // Replace any with Network Data Fail Data format
  return typedAction(OTP_VERIFICATION_FAILURE, details);
}

export function successVerifyOTP(details: OTPVerificationResult) {
  // console.log({details});
  
  return typedAction(OTP_VERIFICATION_SUCCESS, {
    id: details.userID,
  } as UserDetails);
}

// clear OTP
export function clearOTPState() {
  return typedAction(OTP_STATE_CLEAR, "clear");
}

// Save Guest Form
export function saveGuestUser(details: GuestUser) {
  return typedAction(SAVE_GUEST_USER, details);
}

// Save SignUp Form
export function saveSignUpUser(details: SignUpUser) {
  return typedAction(SAVE_SIGNUP_USER, details);
}

// Reset Password form
export function resetPasswordRequest(details: ResetPassword) {
  return typedAction(RESET_PASSWORD_REQUEST, details);
}

export function resetPasswordInitiateSuccess() {
  return typedAction(RESET_PASSWORD_INITIATE_SUCCESS, "");
}

export function resetPasswordSuccess() {
  return typedAction(RESET_PASSWORD_SUCCESS, "");
}

export function resetPasswordFailed(details: string) {
  return typedAction(RESET_PASSWORD_FAILURE, details);
}

export function clearResetPassword() {
  return typedAction(CLEAR_RESET_PASSWORD, "");
}

export function signOut() {
  return typedAction(SIGN_OUT, "");
}

export function getCountry(data: string) {
  return typedAction(GET_COUNTRY_CODE_SUCCESS, data);
}

export function getCountryRequest() {
  return typedAction(GET_COUNTRY_CODE_REQUEST, "");
}

export function setChangeNumberDetails(details: string) {
  return typedAction(SET_NEW_MOBILE_DETAILS, details);
}

// Delete  Edit Profile signIn Data

export function deletesignInData() {
  return typedAction(DELETE_SIGNIN_DATA_EDIT_PROFILE, "");
}
export function setRedeemedWalletAmount(data: string) {
  return typedAction(REDEEMED_WALLER_AMOUNT, data);
}

export function otpClearDefault() {
  return typedAction(CLEAR_OTP_DEFAULT, "");
}

export type AuthActions = ReturnType<
  | typeof storeUser
  | typeof clearUser
  | typeof signIn
  | typeof successSignIn
  | typeof failedSignIn
  | typeof verifyOTP
  | typeof failedVerifyOTP
  | typeof successVerifyOTP
  | typeof verifyMobile
  | typeof failedVerifyMobile
  | typeof successVerifyMobile
  | typeof clearVerifyMobile
  | typeof clearOTPState
  | typeof verifyMobileOTPRequired
  | typeof saveGuestUser
  | typeof saveSignUpUser
  | typeof storeLocationAfterAuth
  | typeof resetPasswordRequest
  | typeof resetPasswordFailed
  | typeof resetPasswordSuccess
  | typeof resetPasswordInitiateSuccess
  | typeof clearResetPassword
  | typeof signOut
  | typeof clearLocationAfterAuth
  | typeof getCountry
  | typeof setChangeNumberDetails
  | typeof deletesignInData
  | typeof setRedeemedWalletAmount
  | typeof otpClearDefault
>;
