import { call, put, takeLatest } from "redux-saga/effects";
import {
  SupportActions,
  createSupportFailure,
  createSupportRequest,
  createSupportSuccess,
} from "./SupportActions";

import {
  CREATE_SUPPORT_FAILURE,
  CREATE_SUPPORT_SUCCESS,
  CREATE_SUPPORT_REQUEST,
} from "./SupportConstants";

import { supportRequest } from "./SupportAPI";

import {
  supportResponseData,
  supportRequestData,
  SupportState,
} from "./SupportModels";

import { AxiosResponse } from "axios";

function* supportRequestSaga(action: SupportActions) {
  try {
    const response: AxiosResponse = yield call(
      supportRequest,
      action.payload as supportRequestData
    );
    if (response.status == 200) {
      const result = response.data as supportResponseData;
      yield put(createSupportSuccess(result));
    } else {
      yield put(createSupportFailure());
    }
  } catch (err) {
    // console.log("Error in Create Support :::::", err);
    yield put(createSupportFailure());
  }
}

export default function* supportSaga() {
  yield takeLatest(CREATE_SUPPORT_REQUEST, supportRequestSaga);
}
