import { Dispatch } from "redux";
import { differenceInMilliseconds, parseISO } from "date-fns";
import moment from "moment";
import { Store } from "../common/configureStore";
import { updateTaskAction, performTaskAction } from "../common/features/scheduler/SchedulerActions";

// Global variables to manage scheduler state
let activeTimeout: NodeJS.Timeout | null = null;  // Stores the current active timer
let unsubscribe: (() => void) | null = null;      // Function to unsubscribe from Redux store
let isScheduling = false;                         // Flag to prevent multiple simultaneous scheduling
let lastApiCallTime: number = 0;                  // Track last API call time
const API_COOLDOWN = 20000;                       // Minimum time between API calls (1 minute)

/**
 * Interface defining the structure of session data required for scheduling
 */
interface SessionData {
    activeSessionEndTime: string | null;          // End time of current active session
    nextSession: {
        nextSessionStartTime: string;             // Start time of next session
        nextSessionDay: string;                   // Day of next session (Today/Tomorrow)
    };
    isLastSession: boolean;                       // Whether this is the last session
    // isActiveOrderType: boolean;                   // Whether current order type is active
    menuType: string;                            // Current menu type (Delivery/Pickup)
    restaurantId: string;                        // Current restaurant ID
    defaultEta: Number;                          // Default ETA for preparation time
}

/**
 * Checks if enough time has passed since the last API call
 * @returns boolean indicating if an API call is allowed
 */
const canMakeApiCall = (): boolean => {
    const now = Date.now();
    if (now - lastApiCallTime >= API_COOLDOWN) {
        lastApiCallTime = now;
        return true;
    }
    return false;
};

/**
 * Retrieves current state from Redux store and formats it for scheduler use
 * @returns Formatted session data or null if required data is missing
 */
const getCurrentState = (): SessionData | null => {
    const state = Store.getState();
    
    // Return null if restaurant details are not available
    if (!state.restaurant?.currentRestaurantDetail) {
        return null;
    }

    const currentRestaurantDetail = state.restaurant.currentRestaurantDetail;
    const currentActivMenuType = state.restaurant.currentActivMenuType;

    // Validate required fields
    if (!currentActivMenuType) {
        return null;
    }

    if (!currentRestaurantDetail.id) {
        return null;
    }

    if (!currentRestaurantDetail.nextSession) {
        return null;
    }

    const deliveryETA = currentRestaurantDetail.defaultDeliveryETA;
    const pickupETA = currentRestaurantDetail.defaultPickUpETA;

    // Determine ETA based on menu type (Delivery/Pickup)
    const deliveryGroupName = currentRestaurantDetail.orderTypes?.find(
        (orderType: any) => orderType.typeGroup === 'S'
    )?.typeName;
    const pickupGroupName = currentRestaurantDetail.orderTypes?.find(
        (orderType: any) => orderType.typeGroup === 'P'
    )?.typeName;

    // Set default ETA based on menu type
    const defaultEta = currentActivMenuType?.toLowerCase() === deliveryGroupName?.toLowerCase() 
        ? deliveryETA 
        : currentActivMenuType?.toLowerCase() === pickupGroupName?.toLowerCase() 
            ? pickupETA 
            : '0';

    // Check if current order type is active
    // const currentOrderTypeIsActive = currentRestaurantDetail.restaurantOrderTypes?.find(
    //     (orderType: any) => orderType.typeName === currentActivMenuType
    // );

    return {
        activeSessionEndTime: currentRestaurantDetail.activeSessionEndTime,
        nextSession: currentRestaurantDetail.nextSession,
        isLastSession: currentRestaurantDetail.isLastActiveSession || false,
        menuType: currentActivMenuType,
        restaurantId: currentRestaurantDetail.id,
        defaultEta:Number(defaultEta)
    };
};

/**
 * Formats date for logging
 */
const formatLogTime = (date: Date) => {
    const localDate = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));
    return {
        time: localDate.toISOString(),
        timestamp: date.getTime()
    };
};

/**
 * Action creator for performing scheduled tasks
 */
const handleTaskAction = (taskType: string, time: string, menuType: string | null, restaurantId: string, isMenuTriggerReq: boolean) => {
    // Set default menuType to Pickup if null
    const finalMenuType = menuType || "Pickup";
    console.log({ finalMenuType });
    
    
    // Only make API call if cooldown period has passed
    if (isMenuTriggerReq && !canMakeApiCall()) {
        return;
    }

    // Get current state
    const state = Store.getState();
    const currentRestaurantDetail = state.restaurant?.currentRestaurantDetail;

    if (!currentRestaurantDetail) {
        return;
    }

    // Make the actual API call
    try {
        // Dispatch the action to update restaurant status
        const action = performTaskAction(taskType, time, finalMenuType, restaurantId, isMenuTriggerReq);
        Store.dispatch(action as any);
    } catch (error) {
        // Error handling without console.log
    }
};

/**
 * Action creator for updating scheduler state
 */
const handleUpdateTask = (taskType: string, time: string | null, isShowPopup: boolean, menuType: string, restaurantId: string) => {
    // Get current state
    const state = Store.getState();
    const currentRestaurantDetail = state.restaurant?.currentRestaurantDetail;

    if (!currentRestaurantDetail) {
        return;
    }

    // Update the UI state
    try {
        Store.dispatch(updateTaskAction(taskType, time, isShowPopup, menuType, restaurantId) as any);
    } catch (error) {
        // Error handling without console.log
    }
};

/**
 * Schedules the next event based on current state and time
 */
const scheduleNext = (currentActicvity: string) => {
    const now = new Date();
    
    // Prevent multiple simultaneous scheduling
    if (isScheduling) {
        return;
    }
    isScheduling = true;

    // Clear any existing timeout
    if (activeTimeout) {
        clearTimeout(activeTimeout);
        activeTimeout = null;
    }

    // Get current state and validate
    const sessionData = getCurrentState();
    
    if (!sessionData) {
        isScheduling = false;
        return;
    }

    // Additional validation for required fields
    if (!sessionData.menuType || !sessionData.restaurantId) {
        isScheduling = false;
        return;
    }

    const nextSessionDay = sessionData.nextSession?.nextSessionDay;
    const preparationTime = sessionData.defaultEta as number || 30 as number; // Default to 30 minutes if not specified

    let nextEvent = null;

    // Calculate next event based on active session end time
    if (sessionData.activeSessionEndTime) {
        const activeEndTime = parseISO(
            `${now.toISOString().split("T")[0]}T${sessionData.activeSessionEndTime}`
        );

        const preparationAlertStart = moment(activeEndTime).subtract(
            preparationTime * 2,
            "minutes"
        );

        const preparationStart = moment(activeEndTime).subtract(
            preparationTime,
            "minutes"
        );

        if (now < preparationAlertStart.toDate()) {
            nextEvent = {
                case: 1,
                currentActicvity,
                type: "No action required before alert time.",
                time: preparationAlertStart.toDate(),
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: false,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else if (now >= preparationAlertStart.toDate() && now < preparationStart.toDate()) {
            nextEvent = {
                case: 2,
                currentActicvity,
                type: "Hurry up! The restaurant will be closing soon. Please place your order without fail.",
                time: preparationStart.toDate(),
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: false,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else if (now < activeEndTime) {
            nextEvent = {
                case: 3,
                currentActicvity,
                type: "We are closing soon, and no longer accepting online orders.",
                time: activeEndTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        } else if (now > activeEndTime ) {
            const nextSessionTime = parseISO(
                `${now.toISOString().split("T")[0]}T${sessionData.nextSession.nextSessionStartTime}`
            );
            nextEvent = {
                case: 4,
                currentActicvity,
                type: `${nextSessionDay !== 'ALL' && nextSessionDay !== 'TODAY' ? `"We are closed. Online ordering will resume on ${sessionData?.nextSession?.nextSessionDay} at ${moment(nextSessionTime)?.format("hh:mm A")}."` : `"We will begin accepting online orders at ${moment(nextSessionTime)?.format("hh:mm A")}."`}`,
                time: nextSessionTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
    } else if (!sessionData.activeSessionEndTime) {
        const nextSessionTime = parseISO(
            `${now.toISOString()?.split("T")[0]}T${sessionData?.nextSession?.nextSessionStartTime}`
        );
        if (sessionData.isLastSession && now > nextSessionTime) {
            nextEvent = {
                case: 5,
                currentActicvity,
                type: `${nextSessionDay !== 'ALL' && nextSessionDay !== 'TODAY' ? `"We are closed. Online ordering will resume on ${sessionData?.nextSession?.nextSessionDay} at ${moment(nextSessionTime)?.format("hh:mm A")}."` : `"We will begin accepting online orders at ${moment(nextSessionTime)?.format("hh:mm A")}."`}`,
                time: nextSessionTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else if (now <= nextSessionTime) {
            nextEvent = {
                case: 6,
                currentActicvity,
                type: `${nextSessionDay !== 'ALL' && nextSessionDay !== 'TODAY' ? `"We are closed. Online ordering will resume on ${sessionData?.nextSession?.nextSessionDay} at ${moment(nextSessionTime)?.format("hh:mm A")}."` : `"We will begin accepting online orders at ${moment(nextSessionTime)?.format("hh:mm A")}."`}`,
                time: nextSessionTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else if (!sessionData.isLastSession && now === nextSessionTime) {
            const activeEndTime = parseISO(
                `${now.toISOString().split("T")[0]}T${sessionData.activeSessionEndTime}`
            );
            const preparationAlertStart = moment(activeEndTime).subtract(
                preparationTime * 2,
                "minutes"
            );

            nextEvent = {
                case: 7,
                currentActicvity,
                type: "No action required before alert time.",
                time: preparationAlertStart.toDate(),
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: false,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else if (!sessionData.isLastSession && now >= nextSessionTime ) {
            nextEvent = {
                case: 8,
                currentActicvity,
                type: `${nextSessionDay !== 'ALL' && nextSessionDay !== 'TODAY' ? `"We are closed. Online ordering will resume on ${sessionData?.nextSession?.nextSessionDay} at ${moment(nextSessionTime)?.format("hh:mm A")}."` : `"We will begin accepting online orders at ${moment(nextSessionTime)?.format("hh:mm A")}."`}`,
                time: nextSessionTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
        else {
            nextEvent = {
                case: 9,
                currentActicvity,
                type: `${nextSessionDay !== 'ALL' && nextSessionDay !== 'TODAY' ? `"We are closed. Online ordering will resume on ${sessionData?.nextSession?.nextSessionDay} at ${moment(nextSessionTime)?.format("hh:mm A")}."` : `"We will begin accepting online orders at ${moment(nextSessionTime)?.format("hh:mm A")}."`}`,
                time: nextSessionTime,
                menuType: sessionData?.menuType,
                restaurantId: sessionData?.restaurantId,
                isShowPopup: true,
                isRequiredAPIcall: true,
                isMenuTriggerReq: true
            };
        }
    }

    // Handle the next event if one exists
    if (nextEvent) {
        console.log({nextEvent});
        // Handle null time case
        if (nextEvent.time === null) {
            handleUpdateTask(nextEvent.type, null, nextEvent.isShowPopup, nextEvent.menuType, nextEvent.restaurantId);
            isScheduling = false;
            return;
        }
        
        // Handle case where API call is not required
        if (!nextEvent.isRequiredAPIcall) {
            handleUpdateTask(nextEvent.type, null, nextEvent.isShowPopup, nextEvent.menuType, nextEvent.restaurantId);
            isScheduling = false;
            return;
        }

        // Schedule the next event
        const delay = Math.max(0, differenceInMilliseconds(nextEvent.time, new Date()) + 3000);
        // Update UI state immediately
        handleUpdateTask(nextEvent.type, nextEvent.time.toISOString(), nextEvent.isShowPopup, nextEvent.menuType, nextEvent.restaurantId);
        currentActicvity === "FROM_VISIBILITY_CHANGE" && 
        handleTaskAction( nextEvent.type, nextEvent.time.toISOString(), nextEvent.menuType, nextEvent.restaurantId, nextEvent.isMenuTriggerReq) 
        
        activeTimeout = setTimeout(() => {
            // Make the API call only if required
            if (nextEvent.isMenuTriggerReq) {
                handleTaskAction(
                    nextEvent.type,
                    nextEvent.time.toISOString(),
                    nextEvent.menuType,
                    nextEvent.restaurantId,
                    nextEvent.isMenuTriggerReq
                );
            }
            
            isScheduling = false;
            // Only schedule next if it's not a state trigger
            if (currentActicvity !== "NEXT_STATE_TRIGGER") {
                scheduleNext("NEXT_STATE_TRIGGER");
            }
        }, delay);
    } else {
        isScheduling = false;
    }
};

/**
 * Initializes the scheduler with visibility change handling
 */
export const initializeScheduler = () => {
    // Set up visibility change handling if not already done
    if (!unsubscribe) {
        let lastVisibilityState = document.visibilityState === 'visible';
        let lastRestaurantState:any = null;
        let lastMenuType:any = null;
        
        // Subscribe to Redux state changes for both visibility and restaurant state
        unsubscribe = Store.subscribe(() => {
            const state = Store.getState();
            const isVisible = state.visibility?.isVisible;
            const currentRestaurantState = state.restaurant?.currentRestaurantDetail;
            const currentMenuType = state.restaurant?.currentActivMenuType;

            // Check if visibility changed
            if (isVisible !== lastVisibilityState) {
                lastVisibilityState = isVisible;
                
                if (isVisible) {
                    scheduleNext('FROM_VISIBILITY_CHANGE');
                } else {
                    if (activeTimeout) {
                        clearTimeout(activeTimeout);
                        activeTimeout = null;
                    }
                    isScheduling = false;
                }
            }

            // Check if restaurant state or menu type changed
            const restaurantStateChanged = JSON.stringify(currentRestaurantState) !== JSON.stringify(lastRestaurantState);
            const menuTypeChanged = currentMenuType !== lastMenuType;

            if (restaurantStateChanged || menuTypeChanged) {
                lastRestaurantState = currentRestaurantState;
                lastMenuType = currentMenuType;

                if (isVisible) {
                    scheduleNext('FROM_STATE_CHANGE');
                }
            }
        });

        // Backup visibility change listener
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                scheduleNext('FROM_VISIBILITY_CHANGE');
            } else {
                if (activeTimeout) {
                    clearTimeout(activeTimeout);
                    activeTimeout = null;
                }
                isScheduling = false;
            }
        });
    }

    // Start initial scheduling
    scheduleNext('INITIAL_TRIGGER');
};

/**
 * Cleanup function to be called when scheduler needs to be stopped
 */
export const cleanupScheduler = (): void => {
    if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
    }
    if (activeTimeout) {
        clearTimeout(activeTimeout);
        activeTimeout = null;
    }
    isScheduling = false;
};
