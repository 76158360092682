import React, { Fragment, useMemo, useEffect } from "react";
import { ReactComponent as CookingImage } from "common/assets/svg/cooking.svg";
import { ReactComponent as CuttingImage } from "common/assets/svg/salonLoading.svg";
import { useHistory, useLocation, useParams } from "react-router";

interface LoadingProps {
  type: "restaurant" | "salon" | "pageLoad";
  containerStyle?: any;
  screen?: string;
}

const Loading = (props: LoadingProps) => {
  const location = useLocation();
  // const isMobile = window.innerWidth <= 500;

  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 500);
  //const isMobile = window.innerWidth <= 500;


  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 500);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  let { restaurantSlug } = useParams<any>();

  const styles = useMemo(() => {
    if (props.containerStyle) {
      return props.containerStyle;
    }
  }, [props]);

  useEffect(() => {
    let component: any = document && document.getElementById("innerContainer");
    if ((!isMobile && component !== null) || location.pathname.includes("/placeOrder")) {
      component.classList.add("desktop");
    }

    return () => {
      if (!isMobile && location.pathname.includes("/payment/status")) {
        component.classList.add("desktop");
      } else if (!isMobile && component !== null && ((location.pathname !== `/restaurant/${restaurantSlug}/placeOrder`) && location.pathname !== "/terms")) {
        component.classList.remove("desktop");
      }
    };
  }, []);

  return (
    <>
      {!location.pathname.includes("landing") && (
        <div
          style={
            styles
              ? styles
              : {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Poppins",
                fontWeight: 500,
                height: "100vh",
                //props.type == "restaurant"  ? "100%" :
              }
          }
        >
          {props.type == "pageLoad" ? <CookingImage /> : null}

          {props.type == "restaurant" ? <CookingImage /> : null}
          {props.type == "salon" ? <CuttingImage /> : null}
          <div
            style={{
              textAlign: "center",
              fontSize: "0.8em",
              // marginBottom: "40%",
            }}
          >
            {props.type == "pageLoad" ? (
              <p
                style={{
                  color: "#525252",
                  fontFamily: "Poppins",
                  marginTop: "100px",
                }}
              >
                {props.screen && props.screen == "payment"
                  ? "Please wait"
                  : "Please wait while we load your choices"}
              </p>
            ) : (
              <p
                style={{
                  color: "#7F7F7F",
                  marginTop: "3rem",
                  fontFamily: "Poppins",
                }}
              >
                Please wait while we load your choices
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
export default Loading;