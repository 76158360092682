import { produce } from "immer";
import { DeliveryActions } from "./DeliveryActions";

import {
  CLEAR_GET_DELIVERY_CHARGE,
  CLEAR_VALET_LOCATION,
  DUNZO_ORDER_CREATED,
  DUNZO_RUNNER_ASSIGNED,
  GET_DELIVERY_CHARGE_FAILURE,
  GET_DELIVERY_CHARGE_INITATED,
  GET_DELIVERY_CHARGE_SUCCEESS,
  GET_DUNZO_TRACKING_CLEAR,
  GET_DUNZO_TRACKING_FAILED,
  GET_DUNZO_TRACKING_INTIATED,
  GET_DUNZO_TRACKING_SUCCESS,
  GET_VALET_LOCATION,
  VALET_LOCATION_SUCCESS,
} from "./DeliveryConstants";

import { DeliveryState } from "./DeliveryModels";

const initialDeliveryState: DeliveryState = {
  getDeliveryChargeLoading: false,
  getDeliveryChargeFailed: false,
  getDeliveryChargeSuccess: false,
  deliveryQuotationDetails: null,
  getDunzotrackingLoading: false,
  getDunzotrackingFailed: false,
  getDunzotrackingSuccess: false,
  dunzoTrackingDetails: null,

  // Instore Valet app tracking
  getValetLocationLoading: false,
  getValetLocationSuccess: false,
  getValetLocationFailure: false,
  valetLocation: null,
};

export default function deliveryReducer(
  state: DeliveryState = initialDeliveryState,
  action: DeliveryActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Delivery Reducers
      case GET_DELIVERY_CHARGE_INITATED:
        draft.getDeliveryChargeLoading = true;
        draft.getDeliveryChargeFailed = false;
        draft.getDeliveryChargeSuccess = false;
        break;
      case GET_DELIVERY_CHARGE_SUCCEESS:
        draft.getDeliveryChargeLoading = false;
        draft.getDeliveryChargeSuccess = true;
        draft.getDeliveryChargeFailed = false;
        draft.deliveryQuotationDetails = action.payload;
        break;
      case GET_DELIVERY_CHARGE_FAILURE:
        draft.getDeliveryChargeLoading = false;
        draft.getDeliveryChargeFailed = true;
        draft.deliveryQuotationDetails = null;
        break;
      case CLEAR_GET_DELIVERY_CHARGE:
        draft.getDeliveryChargeLoading = false;
        draft.getDeliveryChargeFailed = false;
        draft.getDeliveryChargeSuccess = false;
        draft.deliveryQuotationDetails = null;
        break;
      case GET_DUNZO_TRACKING_INTIATED:
        draft.getDunzotrackingLoading = true;
        draft.getDunzotrackingFailed = false;
        draft.getDunzotrackingSuccess = false;
        break;
      case DUNZO_ORDER_CREATED:
        draft.getDunzotrackingFailed = true;
        draft.dunzoTrackingDetails = action.payload;
        break;
      case DUNZO_RUNNER_ASSIGNED:
        draft.getDunzotrackingFailed = true;
        draft.dunzoTrackingDetails = action.payload;
        break;
      case GET_DUNZO_TRACKING_SUCCESS:
        draft.getDunzotrackingLoading = false;
        draft.getDunzotrackingSuccess = true;
        draft.dunzoTrackingDetails = action.payload;
        break;
      case GET_DUNZO_TRACKING_FAILED:
        draft.getDunzotrackingLoading = false;
        draft.getDunzotrackingFailed = true;
        draft.dunzoTrackingDetails = null;
        break;
      case GET_DUNZO_TRACKING_CLEAR:
        draft.getDunzotrackingLoading = true;
        draft.getDunzotrackingFailed = false;
        draft.getDunzotrackingSuccess = false;
        draft.dunzoTrackingDetails = null;
        break;
      case GET_VALET_LOCATION:
        draft.getValetLocationLoading = true;
        draft.getValetLocationSuccess = false;
        draft.getValetLocationFailure = false;
        break;
      case VALET_LOCATION_SUCCESS:
        draft.getValetLocationLoading = false;
        draft.getValetLocationSuccess = true;
        draft.valetLocation = action.payload;
        break;
      case CLEAR_VALET_LOCATION:
        draft.getValetLocationLoading = false;
        draft.getValetLocationSuccess = false;
        draft.valetLocation = null;
        break;
      case GET_DUNZO_TRACKING_FAILED:
        draft.getValetLocationLoading = false;
        draft.getValetLocationFailure = true;
        break;
      case GET_DUNZO_TRACKING_CLEAR:
        draft.getValetLocationLoading = false;
        draft.getValetLocationSuccess = false;
        draft.getValetLocationFailure = false;
        draft.valetLocation = null;
        break;
      default:
        break;
    }
  });
}
