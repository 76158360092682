import API from "common/api";
import {
  OfferValidityRequestData,
  UseOfferRequestData,
  offerRequestBody,
  QuantiyBasedOfferRequest,
  AppliedoffersRequest,
} from "./offferModels";
import { encryptJson } from "common/utils/react-ec-utils";

export function getOffersAPI(data: offerRequestBody) {
  const offerRequestBodyParam =
    data &&
    (data.locationId !== "" || data.couponId !== "" || data.customerId !== "")
      ? `?customerId=${data.customerId}&locationId=${data.locationId}&couponId=${data.couponId}&orderType=${data.orderType}&visibleTo=${data.visibleTo}`
      : "";
      return {};
  // return API({
  //   method: "get",
  //   url: `/coupon/getActiveCoupons${offerRequestBodyParam}`,
  // });
}

export function checkOfferValidityAPI(details: OfferValidityRequestData) {
  const data = encryptJson(details)
  return API({
    method: "get",
    url: "/coupon/v2/validateCoupon",
    data: {"data": data},
  });
}

export function useOfferAPI(details: UseOfferRequestData) {
  const data = encryptJson(details)
  return API({
    method: "post",
    url: "/coupon/v2/redeemCoupon",
    data: {"data":data},
  });
}

export function checkOfferAPI(details: QuantiyBasedOfferRequest) {
     const offerRequestBodyParam= {
        "locationId" : details?.locationId ?details?.locationId:'',
        "customerId" : details?.customerId ?details?.customerId:'',
        "orderType" :  details?.orderType?details?.orderType:"",
        "itemId" : details?.itemId?details.itemId:'',
       "quantity" : details?.quantity?details?.quantity:''
    }
  const data = encryptJson(offerRequestBodyParam)
  return API({
    method: "post",
    url: `/coupon/item/offer/avail/v2`,
    data:{"data": data}
  });
}

export function appliedOffersAPI(details: AppliedoffersRequest) {
  const appliedOfferRequestBodyParam =
    details &&
    details.customerId &&
    details.offerIds &&
    details.orderId &&
    details.orderAmount
      ? {
          customerId: details.customerId,
          offerIds: details.offerIds,
          orderAmount: details.orderAmount,
          orderId: details.orderId,
        }
      : null;
      
  //console.log(appliedOfferRequestBodyParam, "offerRequestBodyParam");
  const data = encryptJson(appliedOfferRequestBodyParam)
  return API({
    method: "patch",
    url: `/coupon/v2/item/offer/apply`,
    data:{"data":data}
  });
}
