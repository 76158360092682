import { typedAction } from "../actionTypes";
import { checkoffer } from "../offers/offferModels";
import { OrderDetail } from "../order/orderModels";
import { STORE_MENU_ITEM } from "../restaurant/restaurantContants";
import {
  RestaurantDetail,
  RestaurantMenuItem,
  Tax,
} from "../restaurant/restaurantModels";
import {
  ADD_CUSTOMISE_INDIVIDUALLY,
  SAVE_ITEM_COMMENTS,
  REMOVE_CUSTOMISE_INDIVIDUALLY,
  ADD_CART_ITEM,
  REMOVE_CART_ITEM,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  MODIFY_CART_ITEM,
  INCREMENT_CART_ITEM,
  DECREMENT_CART_ITEM,
  REMOVE_LAST_ITEM_INSTANCE,
  SAVE_ITEM_CUSTOMISATION,
  START_CUSTOMISE_ITEM,
  FINISH_ITEM_CUSTOMISATION,
  SAVE_ORDER_DETAILS,
  CLEAR_PLACE_ORDER,
  SHOW_FLOATING_CART,
  HIDE_FLOATING_CART,
  REMOVE_CART_TIP,
  START_EMPTY_CART,
  SET_PICKUP_TIME,
  STORE_CART_RESTAURANT_DETAILS,
  SHOW_ITEM_MAX_COUNT_PROMPT,
  HIDE_ITEM_MAX_COUNT_PROMPT,
  LOAD_SAVED_INDIVIDUAL_CUSTOMISATION,
  CLEAR_PLACE_ORDER_SUCCESS,
  ADD_DELIVERY_ADDRESS,
  SET_CART_AUTH_PENDING,
  SET_GUEST_MOBILE_VERIFIED,
  SET_DELIVERY_ORDER_DISTANCE,
  CLEAR_DELIVERY_ORDER_DISTANCE,
  SET_RUNNING_CART,
  CLEAR_RUNNING_CART,
  UPDATE_CART_ITEM,
  CLEAR_CUSTOMISE_ITEM,
  UPDATE_EVENT_ORDER,
  UPDATE_EVENT_ORDER_SLOT,
} from "./cartConstants";
import {
  CartItem,
  Cart,
  CartItemOption,
  CartSaveItem,
  CartInitRequest,
  CartLocationDetail,
} from "./cartModels";

// Item Actions
export function addCartItem(item: CartItem, pseudo: boolean = false) {
 // console.log(item,"--->items value")
  return typedAction(ADD_CART_ITEM, { item: item, pseudo: pseudo });
}

export function removeCartItem(
  itemId: string,
  index: number = -1,
  pseudo: boolean = false
) {
  return typedAction(REMOVE_CART_ITEM, {
    itemId: itemId,
    index: index,
    pseudo: pseudo,
  });
}

export function incrementCartItem(
  itemId: string,
  index: number = -1,
  pseudo: boolean = false
) {
  return typedAction(INCREMENT_CART_ITEM, {
    itemId: itemId,
    index: index,
    pseudo: pseudo,
  });
}
// export function updateCartItem(
//   itemId:string,
//   quantity:number,
//   amount:string,
// ){
//   return typedAction(UPDATE_CART_ITEM, {
//     itemId:itemId,
//     quantity:quantity,
//     amount:amount,
//   });
// }

export function updateCartItem(
  itemId: string,
  amount: string,
  is_discount_applied: boolean = false,
  quantity?: number,
  old_quantity?: number,
  offer_data?: checkoffer,
  pseudo: boolean = false,
  index: number = -1,
  stockQuantity?:number |null,
  alertQuantity?:number | null
 // offer_amount:number = 0,
) {
  return typedAction(UPDATE_CART_ITEM, {
    itemId: itemId,
    amount: amount,
    is_discount_applied: is_discount_applied,
    quantity: quantity ? quantity : 0,
    old_quantity: old_quantity,
    offer_data: offer_data,
    pseudo: pseudo,
    index: index,
   // offer_amount:offer_amount,
   stockQuantity:stockQuantity,
  alertQuantity:alertQuantity
  });
}
export function decrementCartItem(
  itemId: string,
  index: number = -1,
  pseudo: boolean = false
) {
  return typedAction(DECREMENT_CART_ITEM, {
    itemId: itemId,
    index: index,
    pseudo: pseudo,
  });
}

export function saveItemComments(itemId: string, comment: string) {
  return typedAction(SAVE_ITEM_COMMENTS, { itemId: itemId, comment: comment });
}

// Customisation Actions
export function startItemCustomisation(itemDetail: CartItem[]) {
  return typedAction(START_CUSTOMISE_ITEM, itemDetail);
}

export function addCustomiseIndividually(itemId: string) {
  return typedAction(ADD_CUSTOMISE_INDIVIDUALLY, itemId);
}

export function removeCustomiseIndividually(itemId: string) {
  return typedAction(REMOVE_CUSTOMISE_INDIVIDUALLY, itemId);
}

export function loadSavedCustomisation(itemId: string) {
  return typedAction(LOAD_SAVED_INDIVIDUAL_CUSTOMISATION, itemId);
}

export function removeLastItemInstance(itemId: string) {
  return typedAction(REMOVE_LAST_ITEM_INSTANCE, itemId);
}

export function saveItemCustomisation(item: CartSaveItem) {
  return typedAction(SAVE_ITEM_CUSTOMISATION, item);
}

export function finishCustomisation(itemId: string) {
  return typedAction(FINISH_ITEM_CUSTOMISATION, itemId);
}

// export function modifyCartItem(itemDetails: ModifyCartItemDetails) {
//   return typedAction(MODIFY_CART_ITEM, itemDetails);
// }

// Place Order
export function saveOrderDetails(data: {
  tipValue?: number;
  tipType?: "PERCENT" | "FLATFEE"|"";
  orderNote?: string;
}) {
  return typedAction(SAVE_ORDER_DETAILS, {
    tipValue: data.tipValue,
    orderNote: data.orderNote,
    tipType: data.tipType,
  });
}

export function removeCartTip() {
  return typedAction(REMOVE_CART_TIP, "");
}

export function placeOrder(details: Cart) {
  return typedAction(PLACE_ORDER_REQUEST, details);
}

export function failedPlaceOrder(errorMessage:string) {
  return typedAction(PLACE_ORDER_FAILURE, errorMessage);
}

export function successPlaceOrder(orderId: string) {
  return typedAction(PLACE_ORDER_SUCCESS, { orderId: orderId });
}

export function completePlaceOrder() {
  return typedAction(CLEAR_PLACE_ORDER_SUCCESS, "");
}

export function clearPlaceOrder() {
  return typedAction(CLEAR_PLACE_ORDER, "");
}

export function showFloatingCart() {
  return typedAction(SHOW_FLOATING_CART, "");
}

export function hideFloatingCart() {
  return typedAction(HIDE_FLOATING_CART, "");
}

export function startEmptyCart(data: CartInitRequest) {
 
  return typedAction(START_EMPTY_CART, data);
}

export function setPickupTime(time: string, date: string, isASAP: boolean,isEventDate?:boolean) {
  return typedAction(SET_PICKUP_TIME, {
    time: time,
    date: date,
    isASAP: isASAP,
    isEventDate:isEventDate
  });
}

export function storeCartRestaurantDetails(
  restaurantDetails: RestaurantDetail,
  restaurantOrderTax: Tax[],
  restaurantDefaultTax: Tax[]
) {
  return typedAction(STORE_CART_RESTAURANT_DETAILS, {
    restaurantDetails: restaurantDetails,
    restaurantOrderTax: restaurantOrderTax,
    restaurantDefaultTax: restaurantDefaultTax,
  });
}

export function showItemMaxCountPrompt() {
  return typedAction(SHOW_ITEM_MAX_COUNT_PROMPT, "");
}

export function hideItemMaxCountPrompt() {
  return typedAction(HIDE_ITEM_MAX_COUNT_PROMPT, "");
}

export function storeMenuItem(item: RestaurantMenuItem) {
  return typedAction(STORE_MENU_ITEM, item);
}

export function addDeliveryAddress(deliveryAddress: CartLocationDetail) {
  return typedAction(ADD_DELIVERY_ADDRESS, deliveryAddress);
}

export function setCartAuthPending(status: boolean, cartURL: string) {
  return typedAction(SET_CART_AUTH_PENDING, {
    status: status,
    cartURL: cartURL,
  });
}

export function setGuestMobileVerified(value: boolean) {
  return typedAction(SET_GUEST_MOBILE_VERIFIED, value);
}

export function setDeliveryOrderDistance(distance: number) {
  return typedAction(SET_DELIVERY_ORDER_DISTANCE, distance);
}

export function clearDeliveryOrderDistance() {
  return typedAction(CLEAR_DELIVERY_ORDER_DISTANCE, "");
}

export function setRunningCart(cart: Cart) {
  return typedAction(SET_RUNNING_CART, cart);
}

export function clearRunningCart() {
  return typedAction(CLEAR_RUNNING_CART, "");
}

export function clearCutomizationItem(){
  return typedAction(CLEAR_CUSTOMISE_ITEM,'')
}

export function setEventOrder(isEventOrder:boolean,eventDate:string|undefined){
  return typedAction(UPDATE_EVENT_ORDER,{isEventOrder,eventDate})
}

export function setEventOrderSlotTime(eventSlot:string){
  return typedAction(UPDATE_EVENT_ORDER_SLOT,{eventSlot})
}


export type CartActions = ReturnType<
  | typeof addCartItem
  | typeof removeCartItem
  | typeof placeOrder
  | typeof failedPlaceOrder
  | typeof successPlaceOrder
  | typeof completePlaceOrder
  | typeof incrementCartItem
  | typeof decrementCartItem
  | typeof addCustomiseIndividually
  | typeof removeCustomiseIndividually
  | typeof loadSavedCustomisation
  | typeof removeLastItemInstance
  | typeof saveItemCustomisation
  | typeof startItemCustomisation
  | typeof finishCustomisation
  | typeof saveItemComments
  | typeof saveOrderDetails
  | typeof clearPlaceOrder
  | typeof removeCartTip
  | typeof showFloatingCart
  | typeof hideFloatingCart
  | typeof startEmptyCart
  | typeof setPickupTime
  | typeof storeCartRestaurantDetails
  | typeof showItemMaxCountPrompt
  | typeof hideItemMaxCountPrompt
  | typeof storeMenuItem
  | typeof addDeliveryAddress
  | typeof setCartAuthPending
  | typeof setGuestMobileVerified
  | typeof setDeliveryOrderDistance
  | typeof clearDeliveryOrderDistance
  | typeof setRunningCart
  | typeof clearRunningCart
  | typeof updateCartItem
  | typeof clearCutomizationItem
  | typeof setEventOrder
  | typeof setEventOrderSlotTime
>;
