import React from "react";
import {
  Route,
  Switch,
  BrowserRouter,
  Redirect,
  RouteProps,
} from "react-router-dom";
import Loadable from "react-loadable";
// page Imports
import Loading from "components/Loading";
import { merchantTypes } from "common/constants";
import roundToNearestMinutes from "date-fns/roundToNearestMinutes/index";
import ScrollToTop from "./common/utils/scrollToTop";
import PreviewItem from "pages/order/previewItem";
import { handleUrlParamEncrypt } from "common/utils/react-ec-utils";
import { routerParemUpdater } from "common/features/navigation/navigationActions";
import { useDispatch } from "react-redux";
const Home = Loadable({
  loader: () => import("pages/home"),
  loading: () => <></>,
});

const Search = Loadable({
  loader: () => import("pages/search"),
  loading: () => <></>,
});

const SearchRestaurant = Loadable({
  loader: () => import("pages/searchRestaurant"),
  loading: () => <></>,
});

const CheckinWelcome = Loadable({
  loader: () => import("pages/checkInPages/checkInWelcome"),
  loading: () => <Loading type="pageLoad" />,
});

const TableSideOrder = Loadable({
  loader: () => import("pages/tableSideOrder"),
  loading: () => <Loading type="pageLoad" />,
});

const MasterHomePage = Loadable({
  loader: () => import("pages/masterHomePage"),
  loading: () => <Loading type="pageLoad" />,
});

const EventHomePage = Loadable({
  loader: () => import("pages/eventHomePage"),
  loading: () => <Loading type="pageLoad" />,
});

const MasterEventHomePage = Loadable({
  loader: () => import("pages/masterEventHomePage"),
  loading: () => <Loading type="pageLoad" />,
});
const SearchLocation = Loadable({
  loader: () => import("pages/locationPages/searchLocation"),
  loading: () => <></>,
});
const MapViewLocationPicker = Loadable({
  loader: () => import("pages/locationPages/mapViewLocationPicker"),
  loading: () => <></>,
});
const LocationInformation = Loadable({
  loader: () => import("pages/locationPages/locationInformation"),
  loading: () => <></>,
});
const RestaurantHome = Loadable({
  loader: () => import("pages/restaurantHome"),
  loading: () => <Loading type="pageLoad" />,
});
const SignIn = Loadable({
  loader: () => import("pages/authPages/signIn"),
  loading: () => <Loading type="pageLoad" />,
});
const ResetPassword = Loadable({
  loader: () => import("pages/authPages/resetPassword"),
  loading: () => <></>,
});

const PasswordVerifyOTP = Loadable({
  loader: () => import("pages/authPages/passwordOTP"),
  loading: () => <></>,
});
const ResetPasswordUserID = Loadable({
  loader: () => import("pages/authPages/resetPasswordUserID"),
  loading: () => <></>,
});
const SuccessAcknowledge = Loadable({
  loader: () => import("pages/authPages/successAcknowledge"),
  loading: () => <Loading type="pageLoad" />,
});
const SignUp = Loadable({
  loader: () => import("pages/authPages/signUp"),
  loading: () => <></>,
});
const TermsAndConditionScreen = Loadable({
  loader: () => import("pages/authPages/terms"),
  loading: () => <Loading type="pageLoad" />,
});
const QrScan = Loadable({
  loader: () => import("pages/QRReader"),
  loading: () => <></>,
});
const Support = Loadable({
  loader: () => import("pages/support"),
  loading: () => <Loading type="pageLoad" />,
});
const SupportCheckin = Loadable({
  loader: () => import("pages/support/components/checkin/questions"),
  loading: () => <Loading type="pageLoad" />,
});
const SupportCheckinAnswer = Loadable({
  loader: () => import("pages/support/components/checkin/answer"),
  loading: () => <Loading type="pageLoad" />,
});
const RaiseQueryForm = Loadable({
  loader: () => import("pages/support/components/query/queryForm"),
  loading: () => <Loading type="pageLoad" />,
});

const OnlineOrder = Loadable({
  loader: () => import("pages/support/components/onlineOrder"),
  loading: () => <Loading type="pageLoad" />,
});

const QuerySubmit = Loadable({
  loader: () => import("pages/support/components/query/querySubmit"),
  loading: () => <Loading type="pageLoad" />,
});
const FAQs = Loadable({
  loader: () => import("pages/support/components/FAQs"),
  loading: () => <Loading type="pageLoad" />,
});
const Policy = Loadable({
  loader: () => import("pages/support/components/policy"),
  loading: () => <Loading type="pageLoad" />,
});
const Cancellation = Loadable({
  loader: () => import("pages/support/components/policy/canellation"),
  loading: () => <Loading type="pageLoad" />,
});
const Privacy = Loadable({
  loader: () => import("pages/support/components/policy/policy"),
  loading: () => <Loading type="pageLoad" />,
});
const Terms = Loadable({
  loader: () => import("pages/support/components/policy/terms"),
  loading: () => <Loading type="pageLoad" />,
});
const MerchantInfo = Loadable({
  loader: () => import("pages/merchantInfo"),
  loading: () => <Loading type="pageLoad" />,
});
const MerchantInfoDesktop = Loadable({
  loader: () => import("pages/merchantInfoDesktop"),
  loading: () => <Loading type="pageLoad" />,
});
const CheckInConfirmation = Loadable({
  loader: () => import("pages/checkInPages/checkInConfirmation"),
  loading: () => <Loading type="pageLoad" />,
});
const CheckInConfirmationQR = Loadable({
  loader: () => import("pages/checkInPages/checkInConfirmationQR"),
  loading: () => <Loading type="pageLoad" />,
});

const CheckInWelcome = Loadable({
  loader: () => import("pages/checkInPages/checkInWelcome"),
  loading: () => <Loading type="pageLoad" />,
});

const ComingSoon = Loadable({
  loader: () => import("pages/utilPages/comingSoon"),
  loading: () => <Loading type="pageLoad" />,
});
const NotFound = Loadable({
  loader: () => import("pages/utilPages/notFound"),
  loading: () => <Loading type="pageLoad" />,
});
const RestaurantClosed = Loadable({
  loader: () => import("pages/utilPages/restaurantClosed"),
  loading: () => <Loading type="pageLoad" />,
});
const RestaurantPicture = Loadable({
  loader: () => import("components/restaurantPhotos/restaurantPicture"),
  loading: () => <Loading type="pageLoad" />,
});
const Survey = Loadable({
  loader: () => import("pages/survey"),
  loading: () => <Loading type="pageLoad" />,
});
const Takesurvey = Loadable({
  loader: () => import("pages/survey/routeToSurvey"),
  loading: () => <Loading type="pageLoad" />,
});
const CustomizeCart = Loadable({
  loader: () => import("pages/order/cartCustomisation"),
  loading: () => <Loading type="pageLoad" />,
});
const AddTip = Loadable({
  loader: () => import("components/InputComponents/addTip"),
  loading: () => <Loading type="pageLoad" />,
});
const PlaceOrder = Loadable({
  loader: () => import("pages/order/placeOrder"),
  loading: () => <Loading type="pageLoad" />,
});

const PlaceOrderDesktop = Loadable({
  loader: () => import("pages/placeOrderDesktop"),
  loading: () => <Loading type="pageLoad" />,
});
const OrderStatusDesktop = Loadable({
  loader: () => import("pages/orderStatusDesktop"),
  loading: () => <Loading type="pageLoad" />,
});
const CartLocationView = Loadable({
  loader: () => import("pages/locationPages/cartLocationView"),
  loading: () => <Loading type="pageLoad" />,
});
const PreOrderSuccess = Loadable({
  loader: () => import("pages/utilPages/preOrderSuccess"),
  loading: () => <Loading type="pageLoad" />,
});
const StatusCard = Loadable({
  loader: () => import("components/statusCards/reservationStatusCard"),
  loading: () => <Loading type="pageLoad" />,
});
const CartStatusCard = Loadable({
  loader: () => import("components/statusCards/cartStatusCard"),
  loading: () => <Loading type="pageLoad" />,
});
const OrderStatusCard = Loadable({
  loader: () => import("components/statusCards/orderTrackingCard"),
  loading: () => <Loading type="pageLoad" />,
});

const WorkingHoursStatusCard = Loadable({
  loader: () => import("components/statusCards/workingHourStatusCard"),
  loading: () => <Loading type="pageLoad" />,
});

const Guest = Loadable({
  loader: () => import("pages/authPages/guest"),
  loading: () => <Loading type="pageLoad" />,
});
const PaymentOptions = Loadable({
  loader: () => import("pages/payment/paymentOptions"),
  loading: () => <Loading type="pageLoad" />,
});
const NoLocationFound = Loadable({
  loader: () => import("pages/utilPages/NoLocationFound"),
  loading: () => <Loading type="pageLoad" />,
});
const CartScreen = Loadable({
  loader: () => import("pages/order/cart"),
  loading: () => <Loading type="pageLoad" />,
});
const HeartLandCardForm = Loadable({
  loader: () => import("pages/payment/HeartLand"),
  loading: () => <Loading type="pageLoad" />,
});
const FirstDataCardForm = Loadable({
  loader: () => import("pages/payment/FirstData"),
  loading: () => <Loading type="pageLoad" />,
});

const OrderStatus = Loadable({
  loader: () => import("pages/order/orderStatus"),
  loading: () => <Loading type="pageLoad" />,
});
const ContactlessDineInOrderStatus = Loadable({
  loader: () => import("pages/order/contactlessDineInOrderStatus"),
  loading: () => <Loading type="pageLoad" />,
});

const ContactlessDineInRating = Loadable({
  loader: () => import("pages/contactlessDineInRating"),
  loading: () => <Loading type="pageLoad" />,
});
const CheckInSpinner = Loadable({
  loader: () => import("pages/checkInPages/checkInSpinner"),
  loading: () => <></>,
});

const Profile = Loadable({
  loader: () => import("pages/profile/Profile"),
  loading: () => <Loading type="pageLoad" />,
});

const Favourites = Loadable({
  loader: () => import("pages/profile/Favourites"),
  loading: () => <Loading type="pageLoad" />,
});

const Address = Loadable({
  loader: () => import("pages/profile/Address"),
  loading: () => <Loading type="pageLoad" />,
});
const Account = Loadable({
  loader: () => import("pages/profile/Account"),
  loading: () => <Loading type="pageLoad" />,
});
const Checkin = Loadable({
  loader: () => import("pages/profile/Checkin"),
  loading: () => <Loading type="pageLoad" />,
});

const Edit = Loadable({
  loader: () => import("pages/profile/Edit"),
  loading: () => <Loading type="pageLoad" />,
});

const ChangeMobile = Loadable({
  loader: () => import("pages/profile/Change_mobile"),
  loading: () => <Loading type="pageLoad" />,
});

const ChangeEmail = Loadable({
  loader: () => import("pages/profile/Change_email"),
  loading: () => <Loading type="pageLoad" />,
});

const ChangePassword = Loadable({
  loader: () => import("pages/profile/Change_Password"),
  loading: () => <Loading type="pageLoad" />,
});

const MyOrders = Loadable({
  loader: () => import("pages/order/myOrders"),
  loading: () => <Loading type="pageLoad" />,
});

const OrderSummary = Loadable({
  loader: () => import("pages/order/orderSummary"),
  loading: () => <Loading type="pageLoad" />,
});

const PaymentStatus = Loadable({
  loader: () => import("pages/payment/paymentStatus"),
  loading: () => <Loading type="pageLoad" />,
});

const OfferList = Loadable({
  loader: () => import("pages/offer"),
  loading: () => <Loading type="pageLoad" />,
});
const TinyUrl = Loadable({
  loader: () => import("pages/utilPages/tinyURL"),
  loading: () => <Loading type="pageLoad" />,
});

const ActiveCheckIn = Loadable({
  loader: () => import("pages/utilPages/viewActiveCheckin"),
  loading: () => <Loading type="pageLoad" />,
});

const LandingPage = Loadable({
  loader: () => import("pages/guestLandingPage"),
  loading: () => <Loading type="pageLoad" />,
});

const ContactlessDineInLogin = Loadable({
  loader: () => import("pages/contactlessDinenin"),
  loading: () => <Loading type="pageLoad" />,
});

const AppRouter = () => {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth < 500);
  //const isMobile = window.innerWidth <= 500;
  const dispatch = useDispatch();


  React.useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < 500);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const signUpSuccessText = {
    title: "Account Created Successfully",
    content:
      "You can now login with your credentials from the login page by clicking the below button.",
    type: "signup",
  };
  const resetSuccessText = {
    title: "Password Reset Successful",
    content:
      "You can login with your new password from the login page by clicking the below button.",
    type: "reset",
  };

  interface RouteValidatorParams extends RouteProps {
    component: any;
  }

  const RouteValidator = (props: RouteValidatorParams) => {
    let Component = props.component;
    let routeProps = { path: props.path, exact: props.exact };
    let temp = props as any;
    if (
      temp.computedMatch &&
      merchantTypes.includes(temp.computedMatch.params.merchantType)
    ) {
      return (
        <Route
          {...routeProps}
          render={(prop) => {
            return <Component {...prop} />;
          }}
        />
      );
    } else {
      return <Redirect path="*" to="/notFound" />;
    }
  };

  const RouteMobileValidator = (props: RouteValidatorParams) => {
    let Component = props.component;
    let routeProps = { path: props.path, exact: props.exact };
    let temp = props as any;
    if (
      temp.computedMatch &&
      merchantTypes.includes(temp.computedMatch.params.merchantType) && isMobile
    ) {
      return (
        <Route
          {...routeProps}
          render={(prop) => {
            return <Component {...prop} />;
          }}
        />
      );
    }else if (
      temp.computedMatch &&
      merchantTypes.includes(temp.computedMatch.params.merchantType) && !isMobile
    ) {
      const payload = props?.computedMatch?.params;
  
      const {merchantType,merchantSlug} = payload;
      const dPath = `/${merchantType}/${merchantSlug}${props.targetPath}`;

      dispatch(routerParemUpdater(payload))
      return (
        <Redirect
          path="*"
          to={dPath} />
        );
    } else {
      return <Redirect path="*" to="/notFound" />;
    }
  };

  const RouteEncryptor = (props: RouteValidatorParams) => {
    const order_Id = props?.computedMatch?.params?.orderId
    const orderId = handleUrlParamEncrypt(order_Id)
   
      return (
        <Redirect
          path="*"
          to={ `/order/${orderId}/orderSummary`} />
        );
      };
    const RouteOrderEncryptor = (props: RouteValidatorParams) => {
      const {merchantType,merchantSlug} = props?.computedMatch?.params;
      const order_Id = props?.computedMatch?.params?.orderId
      const orderId = handleUrlParamEncrypt(order_Id)
      let Component = props.component;
      let routeProps = { path: props.path, exact: props.exact };
      let temp = props as any;
      if (
        temp.computedMatch &&
        merchantTypes.includes(temp.computedMatch.params.merchantType)
      ) {
        return (
          <Redirect
            path="*"
            to={ `/${merchantType}/${merchantSlug}/order/${orderId}`} />
          ); 
      } else {
        return <Redirect path="*" to="/notFound" />;
      }
    }

    const RouteStateMaintainerWithValidator = (props: any) => {
      let Component = props.component;
      let routeProps = { path: props.path, exact: props.exact };
      let temp = props as any;
      if (
        temp.computedMatch &&
        merchantTypes.includes(temp.computedMatch.params.merchantType) 
      ) {
        const payload = props?.computedMatch?.params;
    
        const {merchantType,merchantSlug} = payload;
        const dPath = `/${merchantType}/${merchantSlug}${props.targetPath}`;
  
        dispatch(routerParemUpdater(payload))
        return (
          <Redirect
            path="*"
            to={dPath} />
          );

        } else {
          return <Redirect path="*" to="/notFound" />;
        }
    };
   
  return (
    <BrowserRouter>
      {/* <StatusCard /> */}
      <ScrollToTop />
      <CartStatusCard />
      {isMobile && <OrderStatusCard />}
      
      <Switch>
        <Route exact path="/qrscan" component={QrScan} />
        <Route exact path="/Support" component={Support} />
        <Route exact path="/Support/checkin" component={SupportCheckin} />
        <Route
          exact
          path="/Support/checkin/solution"
          component={SupportCheckinAnswer}
        />
        <Route exact path="/Support/query" component={RaiseQueryForm} />
        <Route exact path="/Support/query/submit" component={QuerySubmit} />
        <Route exact path="/Support/FAQs" component={FAQs} />
        <Route exact path="/Support/policy" component={Policy} />
        <Route exact path="/Support/policy/terms" component={Terms} />
        <Route exact path="/Support/onlineorder" component={OnlineOrder} />
        <Route
          exact
          path="/Support/policy/cancellation"
          component={Cancellation}
        />
        <Route
          exact
          path="/merchant/:merchantSlug/policy/cancellation"
          component={Cancellation}
        />
        <Route exact path="/Support/policy/notices" component={Privacy} />
        <Route
          exact
          path="/"
          component={() => <Home restaurantViewType="default" />}
        />
        <Route
          exact
          path="/restaurants/location/:lat/:lng/search"
          component={() => <Search />}
        />
        <Route
          exact
          path="/restaurants/location/:lat/:lng/search/:keyword"
          component={() => <SearchRestaurant />}
        />
        <Route exact path="/landing" component={() => <LandingPage />} />
        <Route
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/menu/:menuType/contactlessdinein/landing"
          component={() => <ContactlessDineInLogin />}
        />
        <Route
          exact
          path="/restaurants/city/:cityName"
          component={() => <Home restaurantViewType="cityName" />}
        />
        <Route
          exact
          path="/restaurants/location/:lat/:lng"
          component={() => <Home restaurantViewType="location" />}
        />
        <Route exact path="/signIn" component={SignIn} />
        <Route exact path="/signUp" component={SignUp} />
        <Route exact path="/signUp/wallet/:walletId" component={SignUp} />
        <Route exact path="/guest" component={Guest} />
        <Route exact path="/scanQR" component={QrScan} />
        <Route exact path="/notFound" component={NotFound} />
        <Route
          exact
          path="/signUp/otp"
          component={() => (
            <PasswordVerifyOTP title={"OTP VERIFICATION"} type={"signup"} />
          )}
        />
        <Route
          exact
          path="/otp"
          component={() => (
            <PasswordVerifyOTP title={"OTP VERIFICATION"} type={"checkIn"} />
          )}
        />
        <Route
          exact
          path="/guest/otp"
          component={() => (
            <PasswordVerifyOTP title={"OTP VERIFICATION"} type={"placeOrder"} />
          )}
        />
        <Route
          exact
          path="/resetPassword/otp"
          component={() => (
            <PasswordVerifyOTP title={"FORGOT PASSWORD"} type={"password"} />
          )}
        />
        <Route
          exact
          path="/editNumber/otp"
          component={() => (
            <PasswordVerifyOTP title={"CHANGE MOBILE"} type={"changeNumber"} />
          )}
        />
        <Route
          exact
          path="/signUp/success"
          component={() => (
            <SuccessAcknowledge
              title={signUpSuccessText.title}
              content={signUpSuccessText.content}
              type={signUpSuccessText.type}
            />
          )}
        />
        <Route
          exact
          path="/signUp/success/wallet/:walletId"
          component={() => (
            <SuccessAcknowledge
              title={signUpSuccessText.title}
              content={signUpSuccessText.content}
              type={signUpSuccessText.type}
            />
          )}
        />
        <Route exact path="/terms" component={TermsAndConditionScreen} />
        <Route
          exact
          path="/resetPassword/userId"
          component={ResetPasswordUserID}
        />
        <Route exact path="/resetPassword/new" component={ResetPassword} />{" "}
        <Route
          exact
          path="/resetPassword/success"
          component={() => (
            <SuccessAcknowledge
              title={resetSuccessText.title}
              content={resetSuccessText.content}
              type={resetSuccessText.type}
            />
          )}
        />
        <Route
          exact
          path="/checkIn/:restaurantSlug/closed"
          component={RestaurantClosed}
        />
        {/* <Route
      exact
      path="/checkIn/:restaurantSlug"
      component={checkInSpinner}
    /> */}
        {/* <Route
      exact
      path="/checkIn/edit/:checkInID"
      component={checkInSpinner}
    /> */}
        {/* <Route
      exact
      path="/reservation/:restaurantSlug/:reservationId/QR"
      component={CheckInConfirmationQR}
    />
 */}
        <Route
          exact
          path="/reservation/:merchantSlug/:reservationId"
          component={() => <CheckInConfirmation />}
        />
        {/* <RouteStateMaintainerWithValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/order/:orderId/payment/status"
          targetPath={"/re/table/order/payment_status"}
        /> */}
        <RouteMobileValidator
          exact
          path="/:merchantType/:merchantSlug/menu/:menuType"
          component={() =>  <RestaurantHome />}
          targetPath=""
        />
        <RouteMobileValidator
          exact
          path="/:merchantType/:merchantSlug/menu/:menuType/event/:eventDate"
          component={() =>  <RestaurantHome />}
          targetPath=""
        />
        <RouteMobileValidator
          exact
          path="/:merchantType/:merchantSlug/menu/:menuType/e/:eventDate"
          component={() =>  <RestaurantHome />}
          targetPath=""
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/reservation/:reservationId"
          component={() => <CheckInConfirmation />}
        />
        <Route exact path="/location/search" component={SearchLocation} />
        <Route exact path="/location/pick" component={MapViewLocationPicker} />
        <Route exact path="/location/info" component={LocationInformation} />
        <Route exact path="/cart/direction" component={CartLocationView} />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/checkIn/offline"
          component={CheckInSpinner}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/checkIn/nfc"
          component={CheckInSpinner}
        />
        {/* <Route
          exact
          path="/:merchantType/:merchantSlug/menu/:menuType"
          component={() => <RestaurantHome />}
        />*/}
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId"
          component={() => <MerchantInfo />}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/menu/:menuType"
          component={() => <RestaurantHome />}
        />
         <RouteOrderEncryptor
          exact
          path="/:merchantType/:merchantSlug/tracker/:orderId"
          component={isMobile ? OrderStatus : OrderStatusDesktop}
        />
         <RouteEncryptor
          exact
          path="/tracker/:orderId/orderSummary"
          component={isMobile ? OrderSummary : OrderStatusDesktop}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/order/:orderId/menu/:menuType"
          component={() => <RestaurantHome />}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/menu"
          component={() => <RestaurantHome />}
        />
        <Route
          exact
          path="/:merchantType/:merchantSlug/checkIn/table/:tableId"
          // component={() => <CheckInSpinner />}
          component={() => <TableSideOrder />}
        />
        <Route
          exact
          path="/:merchantType/:merchantSlug/details"
          // component={() => <CheckInSpinner />}
          component={() => <MasterHomePage />}
        />
        <Route
          exact
          path="/:merchantType/:merchantSlug/e/:eventSlug"
          component={() => <EventHomePage />}
        />
         <Route
          exact
          path="/:merchantType/:merchantSlug/home"
          component={() => <MasterEventHomePage />}
        />
        <Route
          exact
          path="/:merchantType/:merchantSlug/tableside/checkIn/:tableId"
          // component={() => <CheckInSpinner />}
          component={() => <CheckinWelcome />}
        />
        <Route
          exact
          path="/:merchantType/:merchantSlug/checkIn/table/:tableId/details"
          component={() => <CheckInSpinner />}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/menu"
          component={() => <RestaurantHome />}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/order/:orderId"
          component={isMobile ? OrderStatus : OrderStatusDesktop}
        />
        <RouteValidator
          exact
          path="/:merchantType/:restaurantSlug/table/:tableId/order/:orderId"
          component={ContactlessDineInOrderStatus}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/order/:orderId/offer"
          component={OfferList}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/order/:orderId/rating"
          component={ContactlessDineInRating}
        />
        <RouteValidator
          exact
          path="/:merchantType/order/:orderId"
          component={isMobile ? OrderStatus : OrderStatusDesktop}
        />
        <Route
          exact
          path="/restaurant/:restaurantSlug/table/:tableId/menu/:menuType/customize/:itemId"
          component={CustomizeCart}
        />
        <Route
          exact
          path="/restaurant/:restaurantSlug/menu/:menuType/customize/:itemId"
          component={CustomizeCart}
        />
        <Route
          exact
          path="/restaurant/:restaurantSlug/menu/:menuType/preview/:itemId"
          component={PreviewItem}
        />
        <RouteValidator
          exact
          path="/:merchantType/:restaurantSlug/menu/:menuType/placeOrder"
          component={PlaceOrder}
        />
        <RouteValidator
          exact
          path="/:merchantType/:restaurantSlug/menu/:menuType/table/:tableId/placeOrder"
          component={PlaceOrder}
        />
        <RouteValidator
          exact
          path="/:merchantType/:restaurantSlug/placeOrder"
          component={PlaceOrderDesktop}
        />
        {/* <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/order/:orderId"
          component={OrderStatusDesktop}
        /> */}
        <RouteValidator
          exact
          path="/:merchantType/:restaurantSlug/menu/:menuType/table/:tableId/order/:orderId/placeOrder"
          component={PlaceOrder}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId"
          component={MerchantInfo}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/survey"
          component={Takesurvey}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/survey/questions"
          component={Survey}
        />
        <Route
          exact
          path="/restaurant/picture/:url"
          component={RestaurantPicture}
        />
        <Route exact path="/comingSoon" component={ComingSoon} />
        <Route exact path="/noService" component={NoLocationFound} />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug"
          component={isMobile ? MerchantInfo : MerchantInfoDesktop}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/campaign/:campaignId"
          component={MerchantInfo}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/checkIn"
          component={CheckInSpinner}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/checkIn/welcome"
          component={CheckInWelcome}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/checkIn/table/:tableId"
          component={CheckInSpinner}
        />
        {/* <Route
      exact
      path="/restaurant/:restaurantSlug"
      component={MerchantInfo}
    />
    <Route
      exact
      path="/salon/:restaurantSlug"
      component={MerchantInfo}
    />
    <Route
      exact
      path="/medical/:restaurantSlug"
      component={MerchantInfo}
    /> */}
        {/* <Route
          exact
          path="/restaurant/:restaurantSlug/placeOrder/success"
          component={PreOrderSuccess}
        /> */}
        <Route exact path="/checkIn/live/track/:locationId" component={ActiveCheckIn} />
        <Route exact path="/cart/:restaurantSlug" component={CartScreen} />
        <Route
          exact
          path="/:merchantType/:restaurantSlug/menu/:menuType/placeOrder/payment"
          component={PaymentOptions}
        />
        <Route
          exact
          path="/:merchantType/:restaurantSlug/menu/:menuType/table/:tableId/order/:orderId/placeOrder/payment"
          component={PaymentOptions}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/placeOrder/payment/heartland"
          component={HeartLandCardForm}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/placeOrder/payment/firstData"
          component={FirstDataCardForm}
        />
        {/* <Route
          exact
          path="/:merchantType/:merchantSlug/placeOrder/payment/error"
          component={PaymentStatus}
        /> */}
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/order/:orderId/payment/status"
          component={PaymentStatus}
        />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/table/:tableId/order/:orderId/payment/status"
          component={PaymentStatus}
        />
        ​​​​​
        <Route exact path="/Tip" component={AddTip} />
        <Route exact path="/user" component={Account} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/editProfile" component={Edit} />
        <Route exact path="/myFavourites" component={Favourites} />
        <Route exact path="/address" component={Address} />
        <Route exact path="/Checkin" component={Checkin} />
        <Route exact path="/profile" component={Edit} />
        <Route exact path="/editNumber" component={ChangeMobile} />
        <Route exact path="/editEmail" component={ChangeEmail} />
        <Route exact path="/changePassword" component={ChangePassword} />
        <Route exact path="/myOrders" component={MyOrders} />
        <RouteValidator
          exact
          path="/:merchantType/:merchantSlug/order/:orderId/orderSummary"
          component={OrderSummary}
        />
        <Route
          exact
          path="/order/:orderId/orderSummary"
          component={isMobile ? OrderSummary : OrderStatusDesktop}
        />
        <Route exact path="/offers" component={OfferList} />
        <Route exact path="/:tinyURLId" component={TinyUrl} />
     
        {/* {'Generic route must be placed at the bottom'} */}
        <Route path="*" to="/notFound" />
      </Switch>
      {isMobile && <WorkingHoursStatusCard />}
    </BrowserRouter>
  );
};

export default AppRouter;
