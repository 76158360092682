import API from "common/api";
import { DistanceRequest, EditOrderRequestCart ,ItemTotalsPayloadInteface,UpdateOrderTip} from "./orderModels";
import { GetOrderDetails } from "../cart/cartModels";
import { encryptJson, handleEncrypt } from "common/utils/react-ec-utils";

// Get a Order Detail
export function getOrderDetails(orderData: GetOrderDetails) {
  const enOrderId = handleEncrypt(orderData?.orderId);
  return API({
    method: "get",
    url: "/order/customer",
    params: orderData.sortOrder
      ? { data: enOrderId, sortOrder: orderData.sortOrder }
      : {
        data: enOrderId,
        },
  });
}

// Get Customer Orders
export function getOrders(customerId: string) {
  return API({
    method: "get",
    url: "/order/getOrders",
    params: {
      search: `customerId:${customerId}`,
      sort: "orderDate,desc",
    },
  });
}

// Get past experience customer orders
export function getLatestOrders(customerId: string) {
  return API({
    method: "get",
    url: "/order/customer/getOrders?sort=orderDate,desc",
    params: {
      search: `customerId:${customerId}`,
      page: "0",
      size: "1000",
      sort: "orderTime,desc",
    },
  });
}

// For Floating Bar
export function getActiveOrders(customerId: string) {
  let data =encryptJson({search:`customerId:${customerId}`,page: 0,
    size: 1000,
    sort: "orderTime,desc"})
  return API({
    method: "post",
    url: "/order/filter/orders/v2",
    data:{'data':data}
  });
}

// Calculate Distance
export function getDistanceBtwResUser(details: DistanceRequest) {
  const detailsData = encryptJson(details);
  return API({
    method: "post",
    url: "/order/getDistance/v2",
    data: {"data": detailsData},
  });
}

export function updateOrderAPI(cartData: EditOrderRequestCart) {
  const data = encryptJson(cartData);

  return API({
    method: "put",
    url: "/order/update/customer",
    data:{"data":data}
    // headers: getAuthHeader(),
  });
}

export function updateOrderTipAPI(cartData: UpdateOrderTip) {
  const data = encryptJson({orderId:cartData.orderId,tip:cartData.tipAmount,tipType:cartData.tipType?cartData.tipType :"FLATFEE"});
  return API({
    method: "put",
    url:`/order/v2/customer/add-tip`,
    data:{"data":data}
    //url: `/order/${cartData.orderId}/totals/tip?tip=${cartData.tipAmount}&tipType=${cartData.tipType?cartData.tipType :"FLATFEE"}&transactionId=${cartData.transactionId}`,
  });
}

export function getKDSOrderDetailAPI(orderId: string) {
  const data = encryptJson({orderId:orderId})
  return API({
    method: "post",
    url: "/kot/v2/getKOTItemsBySortOrder",
    data: {"data": data}
  });
}

export function getFilteredOrders(phone: string) {
  let data = encryptJson({search:`phone:${phone}`,page: 0,
    size: 1000,
    sort: "orderTime,desc"})
  return API({
    method: "post",
    url:"/order/filter/orders/v2",
    data:{'data':data}
  });
}

export function manageItemTotalsApi(payload : ItemTotalsPayloadInteface) {
  const data = encryptJson(payload);
  return API({
    method: 'post',
    url: '/order/customer/totals',
    data: { "data": data},
  });
}