import { AxiosResponse } from "axios";
import { put, call, throttle, takeLatest } from "redux-saga/effects";
import { CartActions, failedPlaceOrder } from "../cart/cartActions";
import { EditOrderRequest, GetOrderDetails } from "../cart/cartModels";
import {
  failedGetOrderDetail,
  OrderActions,
  successGetOrderDetail,
  successgetOrders,
  requestgetOrders,
  errorgetOrders,
  requestgetLatestOrders,
  successgetLatestOrders,
  errorgetLatestOrders,
  getActiveOrderSuccess,
  getActiveOrderFailed,
  successUpdateOrder,
  failedUpdateOrder,
  successUpdateOrderTip,
  failedUpdateOrderTip,
} from "./orderActions";
import {
  getOrderDetails,
  getOrders,
  getLatestOrders,
  getActiveOrders,
  updateOrderAPI,
  getKDSOrderDetailAPI,
  updateOrderTipAPI,
} from "./orderAPI";
import {
  GET_ACTIVE_ORDERS_REQUEST,
  GET_KDS_ORDER_DETAIL_REQUEST,
  GET_LATEST_ORDERS_ERROR,
  GET_LATEST_ORDERS_REQUEST,
  GET_ORDERS_REQUEST,
  GET_ORDER_DETAIL_REQUEST,
  UPDATE_ORDER_REQUEST,
  UPDATE_TIP_REQUEST,
} from "./orderConstants";
import {
  LatestOrder,
  OrderDetail,
  Orders,
  UpdateOrderTip,
} from "./orderModels";
import { decryptJson, handleDecrypt } from "common/utils/react-ec-utils";

function* getOrderDetailSaga(action: OrderActions) {
  // console.log('Get all checkIns',{action});
  try {
    const response: AxiosResponse = yield call(
      getOrderDetails,
      action.payload as GetOrderDetails
    );
    if (response.status === 200) {
      // console.log(response.data);
      const data = decryptJson(response.data.encryptedText)
      yield put(successGetOrderDetail(data as OrderDetail));
    }
  } catch (err) {
    console.log(err);
    yield put(failedGetOrderDetail());
  }
}

function* getKDSOrderDetailSaga(action: OrderActions) {
  try {
    const response: AxiosResponse = yield call(
      getKDSOrderDetailAPI,
      action.payload as string
    );
    if (response.status === 200) {
      const decryptedData = decryptJson(response.data.encryptedText)
      yield put(successGetOrderDetail(decryptedData as OrderDetail));
    }
  } catch (err) {
    console.log(err);
    yield put(failedGetOrderDetail());
  }
}

function* getOrdersSaga(action: OrderActions) {
  try {
    const response: AxiosResponse = yield call(
      getOrders,
      action.payload as string
    );
    if (response.status === 200) {
      // console.log(response.data);
      yield put(successgetOrders(response.data as Orders));
    }
  } catch (err) {
    console.log(err);
    yield put(errorgetOrders());
  }
}

function* latestOrderSaga(action: OrderActions) {
  try {
    const response: AxiosResponse = yield call(
      getLatestOrders,
      action.payload as string
    );
    if (response.status === 200) {
      yield put(successgetLatestOrders(response.data as LatestOrder));
    }
  } catch (err) {
    console.log(err);
    yield put(errorgetLatestOrders());
  }
}

function* activeOrderSaga(action: OrderActions) {
  try {
    const response: AxiosResponse = yield call(
      getActiveOrders,
      action.payload as string
    );
    if (response.status === 200) {
      const data = decryptJson(response.data.encryptedText)
      yield put(getActiveOrderSuccess(data as LatestOrder));
    }
    else{
      yield put(getActiveOrderFailed());
  }
  } catch (err) {
    console.log(err);
    yield put(getActiveOrderFailed());
  }
}

function* updateOrderSaga(action: CartActions) {
  try {
    const requestData = action.payload as EditOrderRequest;
    const cartDetails = requestData.cart;
    const response: AxiosResponse = yield call(updateOrderAPI, cartDetails);    
    if (response.status === 200) {
      const data = decryptJson(response.data.encryptedText)
      // Update order success
      yield put(successUpdateOrder(data));
    } else {
      // Update order failed
      const data = decryptJson(response.data.encryptedText)
      yield put(failedPlaceOrder(data?.message ? data?.message :'Please Retry'));
      yield put(failedUpdateOrder());
    }
  } catch (err) {
    yield put(failedPlaceOrder(err?.response?.data?.message ? err?.response?.data?.message : 'Please Retry'));
    yield put(failedUpdateOrder());
  }
}

function* updateOrderTipSaga(action: CartActions) {
  const requestData = action.payload as UpdateOrderTip;
  try {
    
    const response: AxiosResponse = yield call(updateOrderTipAPI, requestData);
    if (response.status === 200) {
      const data = decryptJson(response.data.encryptedText)
      // Update order success
      yield put(successUpdateOrderTip(data));
      if ( requestData?.sagaCallBack != null &&typeof requestData?.sagaCallBack === 'function') {
        requestData.sagaCallBack(true);
      }
    } else {
      // Update order failed
      yield put(failedUpdateOrderTip());
      if ( requestData?.sagaCallBack != null &&typeof requestData?.sagaCallBack === 'function') {
        requestData.sagaCallBack(false);
      }
    }
  } catch (err) {
    if ( requestData?.sagaCallBack != null &&typeof requestData?.sagaCallBack === 'function') {
      requestData.sagaCallBack(false);
    }
    yield put(failedUpdateOrderTip());
  }
}

export default function* OrderSaga() {
  yield throttle(3000, GET_ORDER_DETAIL_REQUEST, getOrderDetailSaga);
  yield takeLatest(GET_ORDERS_REQUEST, getOrdersSaga);
  yield throttle(3000, GET_LATEST_ORDERS_REQUEST, latestOrderSaga);
  yield throttle(3000, GET_ACTIVE_ORDERS_REQUEST, activeOrderSaga);
  yield throttle(3000, UPDATE_ORDER_REQUEST, updateOrderSaga);
  yield throttle(3000, GET_KDS_ORDER_DETAIL_REQUEST, getKDSOrderDetailSaga);
  yield throttle(3000, UPDATE_TIP_REQUEST, updateOrderTipSaga);
}
