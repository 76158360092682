import { PERFORM_TASK, SYNC_MENU_TASK, UPDATE_TASK } from "./SchedulerConstants";

export interface SchedulerState {
    schedulerMessage: string | null;
    schedulerTime: string | null;
    scheduledTime: string | null;
    isShowPopup:boolean;
    isSyncUpdated?:boolean;
}

const initialState: SchedulerState = {
    schedulerMessage: null,
    schedulerTime:null,
    scheduledTime: null,
    isShowPopup:false,
    isSyncUpdated:true
};

export const schedulerReducer = (
    state = initialState,
    action: { type: string; payload: any }
): SchedulerState => {
    switch (action.type) {
        case PERFORM_TASK:
           // console.log('create action.payload',action.payload);
            return {
                ...state,
                schedulerMessage: action.payload.taskMessage,
                scheduledTime: action.payload.time,
            };
        case UPDATE_TASK:
            return {
                ...state,
                schedulerMessage: action.payload.taskMessage,
                schedulerTime: action.payload.time,
                isShowPopup:action.payload.isShowPopup
            };
        case SYNC_MENU_TASK:
            return {
                ...state,
                isSyncUpdated: !state.isSyncUpdated,
            }
        default:
            return state;
    }
};
