import { AxiosResponse } from "axios";
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DeliveryChargeRequestBody,
  DeliveryQuotationDetails,
  DunzoTrackingDetails,
  getDeliveryDetails,
} from "./DeliveryModels";
import {
  getDeliveryChargeSuccess,
  DeliveryActions,
  getDeliveryChargeFailed,
  getDunzoTrackingSuccess,
  getDunzoTrackingFailed,
  dunzoRunnerAssigned,
  dunzoorderCreated,
  getValetLocationFailure,
  getValetLocationSuccess,
} from "./DeliveryActions";

import {
  dunzoOrderTrackingAPI,
  getDeliveryChargeAPI,
  getValetLocation,
} from "./DeliveryAPI";
import {
  GET_DELIVERY_CHARGE_INITATED,
  GET_DUNZO_TRACKING_INTIATED,
  GET_VALET_LOCATION,
} from "./DeliveryConstants";
import { LocationState } from "../location/locationModels";
import { decryptJson } from "common/utils/react-ec-utils";

function* getDeliveryCharge(action: DeliveryActions) {
  try {
    const response: AxiosResponse = yield call(
      getDeliveryChargeAPI,
      action.payload as DeliveryChargeRequestBody
    );
    const decryptedData = decryptJson(response.data.encryptedText);
    if (response.status === 200 && decryptedData.estimatedPrice >= 0) {
      yield put(
        getDeliveryChargeSuccess(decryptedData as DeliveryQuotationDetails)
      );
    } else {
      yield put(getDeliveryChargeFailed());
    }
  } catch (err) {
    yield put(getDeliveryChargeFailed());
    console.log(err);
  }
}

function* getDunzotrackingDetails(action: DeliveryActions) {
  try {
    const response: AxiosResponse = yield call(
      dunzoOrderTrackingAPI,
      action.payload as getDeliveryDetails
    );
    if (response.status === 200) {
      const decryptData = decryptJson(response.data.encryptedText);
      if (decryptData.runner && decryptData.trackingUrl) {
        yield put(
          getDunzoTrackingSuccess(decryptData as DunzoTrackingDetails)
        );
      } else if (decryptData.runner) {
        yield put(dunzoRunnerAssigned(decryptData as DunzoTrackingDetails));
      } else if (decryptData.eta) {
        yield put(dunzoorderCreated(decryptData as DunzoTrackingDetails));
      } else {
        yield put(getDunzoTrackingFailed());
      }
    } else {
      yield put(getDunzoTrackingFailed());
    }
  } catch (err) {
    yield put(getDunzoTrackingFailed());
    console.log(err);
  }
}

// Instore Valet app tracking
function* getDeliveryValetLocation(action: DeliveryActions) {
  try {
    const response: AxiosResponse = yield call(
      getValetLocation,
      action.payload as string
    );
    if (response.status === 200 && response.data.delivery_staff_id) {
      let data = {
        lat: response.data.latitude,
        lng: response.data.longitude,
      } as LocationState;
      yield put(getValetLocationSuccess(data));
    } else {
      yield put(getValetLocationFailure());
    }
  } catch (err) {
    yield put(getValetLocationFailure());
    console.log(err);
  }
}

export default function* deliverySaga() {
  yield takeLatest(GET_DELIVERY_CHARGE_INITATED, getDeliveryCharge);
  yield takeLatest(GET_DUNZO_TRACKING_INTIATED, getDunzotrackingDetails);
  yield takeLatest(GET_VALET_LOCATION, getDeliveryValetLocation);
}
