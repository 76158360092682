import { RootState } from "common/rootReducer";
import React, { Fragment, useCallback } from "react";
import { ReactComponent as PlusIcon } from "../../common/assets/svg/pluss.svg";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

import "./styles.css";

export interface PriceTextProps {
  price: any;
  size?: "big" | "normal";
  isBill?: boolean;
  isBase?: boolean;
  isMenu?: boolean;
  offerPrice?: number | string;
  isMoblieView?: boolean;
  happyHoursType?: string;
  dishCard?:boolean;
}

const PriceText = (props: PriceTextProps) => {
  const location = useLocation();

  // const restaurantCountry = useSelector((state: RootState) =>
  //   location.pathname.includes("orderSummary")
  //     ? state.restaurant.activeOrderRestaurantDetails &&
  //       state.restaurant.activeOrderRestaurantDetails.country
  //     : state.restaurant.currentRestaurantDetail.country
  // );

  const restaurantCountry = useSelector(
    (state: RootState) => state.restaurant.currentResCountry
  );

  const getCountryPrice = useCallback(
    (price: number) => {
      if (restaurantCountry == "US" || props.isBill) {
        return Number(price).toFixed(2);
      } else {
        if (props.isMenu) {
          return parseFloat(price.toString()).toFixed(2);
        } else {
          return Number(price).toFixed(2).toString();
        }
      }
    },
    [restaurantCountry]
  );

  return (
    <span className={props.size && props.size == "big" ? "price big" : "price"}>
      {+props.price < 0 && <span>-</span>}
      {props.size && props.size == "big" ? (
        <Fragment>
          <p className="money-symbol">
            {restaurantCountry == "US" ? (
              <Fragment>&#36;</Fragment>
            ) : restaurantCountry == "IN" ? (
              <Fragment> &#x20B9;</Fragment>
            ):""}
          </p>
          {/* <p className="happyHoursPrice">{12.49}</p> */}
          <span className="price-digit">
            {props.price ? getCountryPrice(props.price) : 0.0}
          </span>
          {/* <span className="money-sup">00</span> */}
        </Fragment>
      ) : (
        <Fragment>
          {/* {props.price > 0 ? (
            <> */}
          {props.isBase && <PlusIcon />}
          {props?.offerPrice && Number(props?.offerPrice) > 0 ? (
            <div
              className="price-container"
              style={props?.isMoblieView && { marginLeft: "-30px" }}
            >
              <p
                className={
                  props?.happyHoursType !== "SURGE HOUR" && props?.offerPrice ? "offer-money-symbol" : "money-symbol"
                }
              >
                {restaurantCountry == "US" ? (
                  <Fragment>&#36;</Fragment>
                ) : (
                  <Fragment> &#x20B9;</Fragment>
                )}
              </p>

              {
                <span
                  className={
                    props?.happyHoursType !== "SURGE HOUR" && props?.offerPrice ? "offer-price-digit" : "price-digit"
                  }
                >
                  {props?.offerPrice}
                </span>
              }
            </div>
          ) : (
            ""
          )}

         {props?.happyHoursType !== "SURGE HOUR" &&  <div  style={props?.isMoblieView && { marginLeft: "-3px" }} >

            <div  className={props?.offerPrice ? "offer-price-container" : "price-container"}>
              {restaurantCountry == "US" ? (
                <Fragment>&#36;</Fragment>
              ) : restaurantCountry == "IN"?(
                <Fragment> &#x20B9;</Fragment>
              ):null}


              <span
                className={
                  props?.offerPrice && props?.offerPrice > 0
                    ? "happyHours-price-digit"
                    : "price-digit"
                }
              >
                {props.price ? getCountryPrice(props.price) : 0}
              </span>
            </div>
          </div>}


          {/* <span className="money-sup">00</span> */}
        </Fragment>
      )}
    </span>
  );
};

export default PriceText;
