import { encryptJson} from "common/utils/react-ec-utils";
import {
  SignUpForm,
  SignUpData,
  SignInForm,
  OTPVerificationDetails,
  MobileVerificationDetails,
  ResetPassword,
} from "./AuthModels";
import API from "common/api";

// SignUp
export function signUp(userDetails: SignUpData) {
  return API({
    method: "post",
    url: "/customer/sendSMS",
    headers: {
      Authorization: "",
    },
    data: userDetails,
  });
}

// SignIn
export function signIn(userDetails: SignInForm) {
  const data = encryptJson(userDetails);
  return API({
    method: "post",
    url: "/customer/login/v2",
    data: {"data":data},
  });
}

// Mobile
export function verifyMobile(details: MobileVerificationDetails) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/customer/sendSMS",
    params:{data}
  });
}


export function signInGuestApi(details: MobileVerificationDetails) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/guest/user",
    data:details
  });
}

// OTP
export function verifyOTP(details: OTPVerificationDetails) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/customer/validateOTP/v2",
    data: {"data":data},
  });
}

// Reset Password
export function resetPassword(details: ResetPassword) {
  const data = encryptJson(details);
  return API({
    method: "post",
    url: "/customer/resetPassword",
    params:{data}
  });
}

// Get Country Code

export function getCountryCode() {
  return API({
    method: "get",
    url: "/customer/getCountry/v2",
  });
}
