export const stagingConfig = {
  apiKey: "AIzaSyAME7JXIYMB0wKa-v3r91YoFkmthogguQk",
  authDomain: "customerapp-2d3e1.firebaseapp.com",
  databaseURL: "https://customerapp-2d3e1.firebaseio.com",
  projectId: "customerapp-2d3e1",
  storageBucket: "customerapp-2d3e1.appspot.com",
  messagingSenderId: "817909314129",
  appId: "1:817909314129:web:d0362344839712084ffe70",
  measurementId: "G-96YHE8T350",
};

export const productionConfig = {
  apiKey: "AIzaSyAME7JXIYMB0wKa-v3r91YoFkmthogguQk",
  authDomain: "customerapp-2d3e1.firebaseapp.com",
  databaseURL: "https://customerapp-2d3e1.firebaseio.com",
  projectId: "customerapp-2d3e1",
  storageBucket: "customerapp-2d3e1.appspot.com",
  messagingSenderId: "817909314129",
  appId: "1:817909314129:web:b5b6f1cd150f52e64ffe70",
  measurementId: "G-2D30Q4P023"
};