import React, { Fragment, useEffect } from "react";
import "./App.css";

// Redux Imports
import { Provider } from "react-redux";
import { Persistor, Store } from "./common/configureStore";
import AppRouter from "Routes";

import SocketHandler from "common/socket/socketHandler";
import AuthService from "common/services/auth-service";
import { CartWatcherService } from "common/services/cart-watcher-service";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FirebaseAppProvider from "firebase/firebase-app-service";
import FirebaseAnalyticsProvider from "firebase/analytics/firebase-analytics-service";
import { PersistGate } from "redux-persist/integration/react";
import { useVisibilityChange } from "./hooks/useVisibilityChange";
import { cleanupScheduler } from "./helper/schedulerHelperv1";

// Create a wrapper component to use hooks
const AppContent = () => {
  const isMobile = window.innerWidth <= 500;

  // Handle visibility changes
  useVisibilityChange({
    onVisibilityChange: (isVisible) => {
      // Dispatch visibility change event to Redux store
      Store.dispatch({ type: 'VISIBILITY_CHANGE', payload: isVisible });
    }
  });

  useEffect(() => {
    document.documentElement.style.setProperty(
      "--wHeight",
      window.innerHeight + "px"
    );

    document.documentElement.style.setProperty(
      "--wWidth",
      window.innerWidth != null && window.innerWidth > 500
        ? "400px"
        : window.innerWidth + "px"
    );

    window.addEventListener("beforeunload", function (e) {
      // Cancel the event
      e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
      // Chrome requires returnValue to be set
      e.returnValue = "";
    });

    return () => {
      window.removeEventListener("beforeunload", () => {});
      cleanupScheduler();
    };
  }, []);

  useEffect(() => {
    const handleContextmenu = (e:any) => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [])

  // useEffect(() => {
  //   const handleKeyDown = (event:any) => {
  //     if (event.metaKey && event.altKey && event.key === 'Dead' || 'i') {
  //       event.preventDefault(); // Prevent default behavior (opening developer tools)
  //       console.log("CMD+Option+I disabled");
  //     }

  //     if (event.ctrlKey && event.shiftKey && event.key === 'C') {
  //       event.preventDefault(); // Prevent default behavior (opening developer tools)
  //       console.log("Control+Shift+C disabled");
  //     }
  //   };
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //  };
  // }, []); 

  return (
    <div className="psuduoRoute">
      <div id="innerContainer">
        <AuthService>
          <FirebaseAppProvider>
            <FirebaseAnalyticsProvider>
              <SocketHandler>
                <Fragment>
                  <ToastContainer autoClose={3000} />
                  <CartWatcherService />
                  <AppRouter />
                </Fragment>
              </SocketHandler>
            </FirebaseAnalyticsProvider>
          </FirebaseAppProvider>
        </AuthService>
      </div>
    </div>
  );
};

// Main App component
function App() {
  return (
    <Provider store={Store}>
      <PersistGate loading={<div>Loading...</div>} persistor={Persistor}>
        <AppContent />
      </PersistGate>
    </Provider>
  );
}

export default App;
