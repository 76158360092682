import { flush } from "@redux-saga/core/effects";
import Branches from "components/Branches";
import { tr } from "date-fns/locale";
import { produce, enableMapSet } from "immer";
import { act } from "react-dom/test-utils";
import { OrderActions } from "./orderActions";
import {
  CLEAR_GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_FAILURE,
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDERS_ERROR,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_REQUEST,
  GET_LATEST_ORDERS_ERROR,
  GET_LATEST_ORDERS_REQUEST,
  GET_LATEST_ORDERS_SUCCESS,
  SET_CURRENT_ORDER_TYPE,
  CLEAR_LATEST_ORDERS,
  GET_ACTIVE_ORDERS_REQUEST,
  GET_ACTIVE_ORDERS_ERROR,
  GET_ACTIVE_ORDERS_SUCCESS,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_FAILURE,
  CLEAR_UPDATE_ORDER,
  GET_KDS_ORDER_DETAIL_REQUEST,
  RESET_GET_ORDER_DETAIL,
  CLEAR_ORDER_DETAILS,
  CLEAR_CURRENT_ORDER_TYPE,
  CLEAR_TIP_DETAIL,
  UPDATE_TIP_FAILURE,
  UPDATE_TIP_REQUEST,
  UPDATE_TIP_SUCCESS,
  SAVE_ORDER_DETAILS,
  START_EMPTY_CART_ORDER,
  TOTALS_API_LOADER_STATUS_UPDATE,
} from "./orderConstants";
import { OrderDetail, OrdersState } from "./orderModels";
import { CLEAR_PLACE_ORDER, START_EMPTY_CART } from "../cart/cartConstants";

enableMapSet();

const initialCheckInState: OrdersState = {
  currentOrderDetail: null,
  orderDetailLoading: false,
  orderDetailSuccess: false,
  allOrders: null,
  latestOreders: null,
  currentOrderType: null,
  activeOrders: null,

  updateOrderLoading: false,
  updateOrderSuccess: false,
  updateOrderFailure: false,
  updateOrderTipDetailsLoading: false,
  updateOrderTipDetailsSuccess: false,
  updateOrderTipDetailsFailed: false,

  tip:'0.0',
  tipType:'',
  discountValue:'0.0',
  discountType:"",
  isDiscountApplied:false,
  totals:[],
  orderTotal:'0.0',
  orderItems:[],
  selectedTableName:"",
  selectedSectionId:"",
  orderTypeId:'',

  destinationLat:"",
  destinationLon:"",
  sourceLat:"",
  sourceLon:"",

  totalsApiLoading:false,

};

export default function orderReducer(
  state: OrdersState = initialCheckInState,
  action: OrderActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // Get Order Detail
      case GET_ORDER_DETAIL_REQUEST:
        draft.orderDetailLoading = true;
        draft.currentOrderDetail = null;
        draft.orderDetailSuccess = false;
        break;
      case GET_KDS_ORDER_DETAIL_REQUEST:
        draft.orderDetailLoading = true;
        draft.currentOrderDetail = null;
        draft.orderDetailSuccess = false;
        break;
      case GET_ORDER_DETAIL_SUCCESS:
        draft.orderDetailLoading = false;
        draft.currentOrderDetail = action.payload;
        draft.orderDetailSuccess = true;
        break;

      case CLEAR_CURRENT_ORDER_TYPE:
        draft.currentOrderType = null;
        break;
      case CLEAR_ORDER_DETAILS:
        draft.orderDetailLoading = false;
        draft.currentOrderDetail = null;
        draft.orderDetailSuccess = false;
        break;
      case GET_ORDER_DETAIL_FAILURE:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = false;
        break;
      case CLEAR_GET_ORDER_DETAIL_REQUEST:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = false;
        break;
      case RESET_GET_ORDER_DETAIL:
        draft.currentOrderDetail = null;
        break;
      case GET_ORDERS_REQUEST:
        draft.orderDetailLoading = false;
        // draft.currentOrderDetail = action.payload;
        break;
      case GET_ORDERS_SUCCESS:
        draft.allOrders = action.payload;
        draft.currentOrderType = null;
        break;
      case GET_LATEST_ORDERS_REQUEST:
        draft.orderDetailLoading = true;
        draft.latestOreders = null;
        draft.orderDetailSuccess = false;
        break;
      case GET_LATEST_ORDERS_SUCCESS:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = true;
        draft.latestOreders = action.payload;
        break;
      case CLEAR_LATEST_ORDERS:
        draft.latestOreders = null;
        draft.activeOrders = null;

        break;
      case GET_LATEST_ORDERS_ERROR:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = false;
        break;
      case SET_CURRENT_ORDER_TYPE:
        draft.currentOrderType = action.payload;
        break;

      // For Floating Cart
      case GET_ACTIVE_ORDERS_REQUEST:
        draft.orderDetailLoading = true;
        draft.activeOrders = null;
        draft.orderDetailSuccess = false;
        break;
      case GET_ACTIVE_ORDERS_SUCCESS:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = true;
        draft.activeOrders = action.payload;
        break;
      case GET_ACTIVE_ORDERS_ERROR:
        draft.orderDetailLoading = false;
        draft.orderDetailSuccess = false;
        break;
      case UPDATE_ORDER_REQUEST:
        draft.updateOrderLoading = true;
        break;
      case UPDATE_ORDER_SUCCESS:
        draft.updateOrderSuccess = true;
        draft.updateOrderLoading = false;
        draft.currentOrderDetail = action.payload;
        break;
      case UPDATE_ORDER_FAILURE:
        draft.updateOrderFailure = true;
        draft.updateOrderLoading = false;
        break;
      case CLEAR_UPDATE_ORDER:
        draft.updateOrderSuccess = false;
        draft.updateOrderFailure = false;
        break;

      case UPDATE_TIP_REQUEST:
        // draft.updateOrderLoading = true;

        draft.updateOrderTipDetailsLoading = true;
        draft.updateOrderTipDetailsSuccess = false;
        draft.updateOrderTipDetailsFailed = false;
        break;
      case UPDATE_TIP_SUCCESS:
        draft.updateOrderTipDetailsLoading = false;
        draft.updateOrderTipDetailsSuccess = true;
        draft.updateOrderTipDetailsFailed = false;
        break;
      case UPDATE_TIP_FAILURE:
        draft.updateOrderTipDetailsLoading = false;
        draft.updateOrderTipDetailsSuccess = false;
        draft.updateOrderTipDetailsFailed = true;
        break;
      case CLEAR_TIP_DETAIL:
        draft.updateOrderTipDetailsLoading = false;
        draft.updateOrderTipDetailsSuccess = false;
        draft.updateOrderTipDetailsFailed = false;
        break;

        case SAVE_ORDER_DETAILS:
          if (action.payload.tip != null ) {
            draft.tip = action.payload.tip;
          }
          if (action.payload.tipType != null ) {
            draft.tipType = action.payload.tipType;
          }
          if (action.payload.discountValue != null && action.payload.discountType != null) {
            draft.discountValue = action.payload.discountValue;
            draft.discountType = action.payload.discountType;
            draft.isDiscountApplied = Number(action.payload.discountValue) > 0 ? true : false;
          }
          
          if (action.payload.totals) {
            draft.totals = action.payload.totals
          }

          if(action?.payload?.orderTotal){
            draft.orderTotal = action.payload.orderTotal;
          }

          if(action?.payload?.orderItems != null && action?.payload?.orderItems?.length > 0){
            draft.orderItems = action.payload.orderItems;
          } 

          if(action?.payload?.selectedTableName != null){
            draft.selectedTableName = action.payload.selectedTableName;
          }

          if(action?.payload?.selectedSectionId != null ){
            draft.selectedSectionId = action.payload.selectedSectionId;
          }

          if(action?.payload?.orderTypeId != null ){
            draft.orderTypeId = action.payload.orderTypeId;
          }

          draft.destinationLat = action.payload.destinationLat;
          draft.destinationLon =action.payload.destinationLon;
          draft.sourceLat = action.payload.sourceLat;
          draft.sourceLon = action.payload.sourceLon;
  
          break;

       case START_EMPTY_CART_ORDER:
          draft.tip='0.0';
          draft.tipType='';
          draft.discountValue='0.0';
          draft.discountType="";
          draft.isDiscountApplied=false;
          draft.totals=[];
          draft.orderTotal='0.0';
          draft.orderItems=[];
          draft.selectedTableName="";
          draft.selectedSectionId="";
       break;

       case TOTALS_API_LOADER_STATUS_UPDATE:
        draft.totalsApiLoading = action.payload.status;
        break;

      default:
        break;
    }
  });
}
