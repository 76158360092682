import { tinyURLState } from "./tinyURLModels";
import { tinyURLActions } from "./tinyURLActions";
import {
  GET_TINYURL_ERROR,
  GET_TINYURL_REQUEST,
  GET_TINYURL_SUCCESS,
  CLEAR_USE_TINYURL,
} from "./tinyURLConstants";

import { produce } from "immer";

const initialCheckInState: tinyURLState = {
  tinyURLLoading: false,
  tinyURLSuccess: false,
  tinyURLFailure: false,
  tinyURLError: null,
  offerValidLoading: false,
  MetaResult: null,
};
export default function tinyURLReducer(
  state: tinyURLState = initialCheckInState,
  action: tinyURLActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_TINYURL_REQUEST:
        draft.tinyURLLoading = true;
        break;
      case GET_TINYURL_SUCCESS:
        draft.MetaResult = action.payload;
        draft.tinyURLSuccess = true;
        draft.tinyURLLoading = false;
        break;
      case GET_TINYURL_ERROR:
        draft.tinyURLFailure = true;
        draft.tinyURLLoading = false;
        break;
      default:
        break;
    }
  });
}
