import { produce } from "immer";
import { act } from "react-dom/test-utils";
import { setPromoConsentSuccess, userActions } from "./userActions";
import {
  USERGIVEN_DETAIL_FAILURE,
  USERGIVEN_DETAIL_REQUEST,
  USERGIVEN_DETAIL_SUCCESS,
  CLEAR_USER_DATA,
  EDIT_EMAIL_FAILURE,
  EDIT_EMAIL_SUCCESS,
  EDIT_EMAIL_REQUEST,
  SET_PROMO_CONSENT_REQUEST,
  SET_PROMO_CONSENT_SUCCESS,
  SET_PROMO_CONSENT_FAILED,
} from "./userConstants";
import { UserState } from "./userModels";
const initialUserState: UserState = {
  currentUserDetail: {
    userId: "",
    userName: "",
    email: "",
    phoneNumber: "",
    tableBooking: [],
    favRestaurants: [],
    favItems: [],
    userLocationDetails: [],
    isPromoSubscribed: null,
    walletAmount:0
  },
  currentUserSlug: "",
  userDetailLoading: false,
  userUpdateLoading: false,
  userDetailFailMessage: "",
  editEmailFailMessage: "",
  editEmailLoading: false,
  editEmailSuccess: false,
  newEmail: "",
  newEmailData: null,
  setPromoConsentErrorMessage: "",
  setPromoConsentSuccess: false,
  setPromoConsentLoading: false,
};

export default function userReducer(
  state: UserState = initialUserState,
  action: userActions
) {
  return produce(state, (draft) => {
    switch (action.type) {
      // CheckIn Reducers
      case USERGIVEN_DETAIL_REQUEST:
        draft.userDetailLoading = true;
        draft.currentUserSlug = action.payload;
        break;
      case USERGIVEN_DETAIL_SUCCESS:
        draft.userDetailLoading = false;
        draft.currentUserDetail = action.payload;
        break;
      case USERGIVEN_DETAIL_FAILURE:
        draft.userDetailLoading = false;
        draft.userDetailFailMessage = action.payload;
        break;
      case CLEAR_USER_DATA:
        draft.currentUserDetail = {
          userId: "",
          userName: "",
          email: "",
          phoneNumber: "",
          tableBooking: [],
          favRestaurants: [],
          favItems: [],
          userLocationDetails: [],
          isPromoSubscribed: null,
          walletAmount:0
        };
        break;
      case EDIT_EMAIL_REQUEST:
        draft.newEmail = action.payload;
        draft.editEmailLoading = true;
        draft.editEmailSuccess = false;
        break;
      case EDIT_EMAIL_SUCCESS:
        draft.editEmailLoading = false;
        draft.editEmailSuccess = true;
        draft.newEmailData = action.payload;
        break;
      case EDIT_EMAIL_FAILURE:
        draft.editEmailLoading = false;
        draft.editEmailFailMessage = action.payload;
        draft.editEmailSuccess = false;
        break;

      // For Promotional Messages
      case SET_PROMO_CONSENT_REQUEST:
        draft.setPromoConsentLoading = true;
        draft.setPromoConsentSuccess = false;
        break;
      case SET_PROMO_CONSENT_SUCCESS:
        draft.setPromoConsentLoading = false;
        draft.setPromoConsentSuccess = true;
        break;
      case SET_PROMO_CONSENT_FAILED:
        draft.setPromoConsentLoading = false;
        draft.setPromoConsentSuccess = false;
        draft.setPromoConsentErrorMessage = action.payload;
        break;
      default:
        break;
    }
  });
}
