import { PERFORM_TASK, UPDATE_TASK } from "./SchedulerConstants";

export const performTaskAction = (taskMessage: string, time: string, menuType: string, restaurantId: string, isMenuTriggerReq:boolean) => {
   // console.log('create called ', time);
    return {
        type: PERFORM_TASK,
        payload: { taskMessage, time, menuType, restaurantId, isMenuTriggerReq },
    };
};

export const updateTaskAction = (taskMessage: string, time: string | null, isShowPopup:boolean, menuType: string, restaurantId: string) => {    
    return {
        type: UPDATE_TASK,
        payload: { taskMessage, time, isShowPopup },
    };
};