interface VisibilityState {
  isVisible: boolean;
}

const initialState: VisibilityState = {
  isVisible: document.visibilityState === 'visible'
};

const visibilityReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'VISIBILITY_CHANGE':
      return {
        ...state,
        isVisible: action.payload
      };
    default:
      return state;
  }
};

export default visibilityReducer; 