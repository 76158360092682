import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

interface VisibilityChangeHandler {
  onVisibilityChange: (isVisible: boolean) => void;
}

export const useVisibilityChange = ({ onVisibilityChange }: VisibilityChangeHandler) => {
  const dispatch = useDispatch();
  const lastVisibilityState = useRef(document.visibilityState === 'visible');

  useEffect(() => {
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === 'visible';
      
      // Only dispatch if the state actually changed
      if (isVisible !== lastVisibilityState.current) {
        lastVisibilityState.current = isVisible;
        onVisibilityChange(isVisible);
      }
    };

    // Add event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial state
    handleVisibilityChange();

    // Cleanup
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [onVisibilityChange]);
}; 